<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="classList ? classList : 'w-6 h-6'"
    viewBox="0 0 512 512"
  >
    <path
      d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z"
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-width="32"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="32"
      d="M400 80v352"
    />
  </svg>
</template>

<script>
  export default {
    name: 'AnglePipeRightIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
