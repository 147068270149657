<!-- Settings Drawer -->
<template>
  <div
    class="container px-[24px] pb-8 overflow-y-scroll bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full"
  >
    <div class="tab-wrapper">
      <button
        id="connection-tab"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'connections',
          'inactive-tab': activeTab !== 'connections',
        }"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="false"
        @click="changeTab('connections')"
      >
        Connections
      </button>
      <button
        id="connection-tab"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'skills',
          'inactive-tab': activeTab !== 'skills',
        }"
        type="button"
        role="tab"
        aria-controls="skills"
        aria-selected="false"
        @click="changeTab('skills')"
      >
        Skills
      </button>
      <button
        id="billing-tab"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'billing',
          'inactive-tab': activeTab !== 'billing',
        }"
        type="button"
        role="tab"
        aria-controls="billing"
        aria-selected="false"
        @click="changeTab('billing')"
      >
        Billing
      </button>
      <button
        id="users-tab"
        class="primary-tab"
        :class="{
          'active-tab': activeTab === 'users',
          'inactive-tab': activeTab !== 'users',
        }"
        type="button"
        role="tab"
        aria-controls="users"
        aria-selected="false"
        @click="changeTab('users')"
      >
        Users
      </button>
    </div>
    <div id="myTabContent">
      <ConnectionsTab v-if="activeTab === 'connections'" />
      <BillingTab
        v-else-if="activeTab === 'billing'"
        @show-notification="showNotification"
      />
      <UsersTab v-else-if="activeTab === 'users'" />
      <ModulesTab v-else-if="activeTab === 'skills' || activeTab === 'modules'" />
    </div>
  </div>

  <!-- notification toast messages bind it to changes to variable message-->
</template>

<script>
  import BillingTab from './components/BillingTab.vue'
  import ModulesTab from './components/ModulesTab.vue'
  import UsersTab from './components/usersTab/UsersTab.vue'
  import ConnectionsTab from './components/connectionsTab/ConnectionsTab.vue'
  import { useRouter } from 'vue-router'
  import { useGlobalStore } from './stores/globalStore'

  export default {
    name: 'SettingsPage',
    components: {
      UsersTab,
      BillingTab,
      ConnectionsTab,
      ModulesTab,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify
      const router = useRouter()

      const changeTab = tab => {
        router.push(`/settings/${tab}`)
      }

      return {
        changeTab,
        notify,
      }
    },
    data() {
      return {
        message: '',
        isSuccess: false,
      }
    },
    computed: {
      activeTab() {
        const path = this.$route.path.replace('/settings', '')
        if (path === '/' || path === '/connections') {
          return 'connections'
        } else if (path === '/modules' || path === '/skills') {
          return 'skills'
        } else if (path === '/billing') {
          return 'billing'
        } else if (path === '/users') {
          return 'users'
        } else {
          return 'connections'
        }
      },
    },
    methods: {
      showNotification({ message, isSuccess }) {
        if (isSuccess) {
          this.notify.success(message)
        } else {
          this.notify.error(message)
        }
      },
    },
  }
</script>
