<template>
  <div
    v-if="show"
    class="bg-gray-900 bg-opacity-50 dark:bg-opacity-50 fixed inset-0 z-[999] cursor-pointer w-full"
    @click.stop="show = false"
  >
    <div class="flex items-center justify-center min-h-screen">
      <div
        class="fixed inset-0 transition-opacity"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75" />
      </div>

      <div
        class="bg-gray-100 dark:bg-gray-900 rounded-16 overflow-hidden shadow-xl transform transition-all w-[95%] max-w-[350px] mx-auto"
      >
        <div class="px-4 py-5 sm:p-6 lg:p-8">
          <div class="mb-4">
            <slot />
          </div>

          <div class="space-y-4">
            <button
              class="primary-btn !w-full !max-w-full"
              :class="confirmBtnClass"
              @click="saveChanges"
            >
              {{ confirmBtnText || 'Save Changes' }}
            </button>
            <button
              class="gray-btn !w-full !max-w-full"
              :class="cancelBtnClass"
              @click="discardChanges"
            >
              {{ cancelBtnText || 'Discard' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Popup',
    props: {
      confirmBtnText: {
        type: String,
        default: 'Save Changes',
      },
      cancelBtnText: {
        type: String,
        default: 'Discard',
      },
      cancelBtnClass: {
        type: String,
        default: '',
      },
      confirmBtnClass: {
        type: String,
        default: '',
      },
    },
    emits: ['confirm', 'cancel'],
    data() {
      return {
        show: false,
      }
    },
    methods: {
      discardChanges() {
        this.$emit('cancel')
      },
      saveChanges() {
        this.$emit('confirm')
      },
      showPopup() {
        this.show = true
      },
      hidePopup() {
        this.show = false
      },
    },
  }
</script>
