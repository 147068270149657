<template>
  <div class="p-6 pt-0 md:p-8 min-h-screen container">
    <h1 class="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-500 flex items-center gap-1">
      Design System
      <span class="text-sm text-gray-500 dark:text-gray-400">
        {{ `
        <dev />
        ` }}
      </span>
    </h1>

    <!-- Notes Section -->
    <section class="mb-10">
      <h2 class="components-header">
        Notes
      </h2>
      <div class="max-w-[840px] space-y-1">
        <p class="text-gray-800 dark:text-gray-200">
          This page is a collection of components that are used in the application. You can use these components in your
          project by copying the code snippets provided below each section.
        </p>
        <p class="text-gray-800 dark:text-gray-200">
          For icons, please visit
          <a
            href="https://icon-sets.iconify.design/"
            target="_blank"
            class="text-primary"
          >Iconify</a>
          and search for the icon you want to use. Copy the icon name and use it in the Icon component.
        </p>
        <p class="text-gray-800 dark:text-gray-200">
          <a
            href="https://icon-sets.iconify.design/hugeicons"
            target="_blank"
            class="text-primary"
          >Hugeicons</a>
          is the default icon set used in the application.
        </p>
        <p class="text-gray-800 dark:text-gray-200">
          If the code snippets are not working, please go to
          <span class="font-bold">DesignSystemPage.vue</span>
          file in the codebase and copy the code from there.
        </p>
      </div>
    </section>

    <!-- Buttons Section -->
    <section class="mb-10">
      <h2 class="components-header">
        Buttons
      </h2>
      <div class="flex items-center gap-4 flex-wrap">
        <button class="primary-btn">
          Primary Button
        </button>
        <button
          class="primary-btn !bg-red-500 hover:!bg-red-600"
          @click="$refs.secretPopup.showPopup()"
        >
          Danger Button
        </button>
        <button class="gray-btn">
          <Icon
            icon="hugeicons:sparkles"
            class="w-4 h-4 text-[#262626] dark:text-white"
          />
          <span>Suggest Button</span>
        </button>
        <button class="gray-btn">
          <Icon
            icon="hugeicons:play"
            class="w-4 h-4 text-[#262626] dark:text-white"
          />
          Gray Button With Icon
        </button>
        <button class="outline-btn">
          Outline Button
        </button>
        <button class="secondary-btn">
          Secondary Button
        </button>
        <button
          class="cancel-btn"
          @click="$refs.secretPopupTwo.showPopup()"
        >
          Cancel Button
        </button>
      </div>
      <monaco-editor
        :value="buttonCode"
        language="html"
        theme="vs-dark"
        height="200"
        class="components-code-editor"
      />
      <Popup
        ref="secretPopup"
        confirm-btn-text="Undo"
        cancel-btn-text="Call my bluff"
        confirm-btn-class="!font-bold"
        cancel-btn-class="!bg-red-500 hover:!bg-red-600 !text-white !font-bold"
        @cancel="$refs.secretPopup.hidePopup(), notify.success('Just kidding! 😂')"
        @confirm="notify.success('Just kidding! 😂')"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">
            Why Click Me??
          </h2>
          <p class="text-sm max-w-xs text-center">
            You clicked me! I have made a request to the server to delete all your data. Click the button below to undo
            the action.
          </p>
        </div>
      </Popup>
      <Popup
        ref="secretPopupTwo"
        confirm-btn-text="Ok. I'm sorry"
        cancel-btn-text="You're fired!"
        @cancel="$refs.secretPopupTwo.hidePopup(), notify.error('Definitely not fired! 🔥')"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">
            Congratulations! 🎉
          </h2>
          <p class="text-sm max-w-xs text-center">
            You found another secret modal!
            <br>
            Consider spending your time writing code instead of clicking random buttons.
          </p>
        </div>
      </Popup>
    </section>

    <!-- Inputs Section -->
    <section class="mb-10">
      <h2 class="components-header">
        Inputs
      </h2>
      <div class="flex items-center gap-4 flex-wrap">
        <input
          type="text"
          placeholder="Primary input"
          class="primary-input max-w-[340px]"
        >
        <Search />
        <ResizableTextarea
          placeholder="Resizable textarea with minrows 5 and maxrows 10"
          class="w-full"
          :min-rows="5"
          :max-rows="10"
        />
      </div>
      <monaco-editor
        :value="inputCode"
        language="html"
        theme="vs-dark"
        height="200"
        class="components-code-editor"
      />
    </section>

    <!-- Modals Section -->
    <section class="mb-10">
      <h2 class="components-header">
        Modals
      </h2>
      <div class="flex items-center gap-4">
        <button
          class="primary-btn"
          @click="$refs.savePopup.showPopup()"
        >
          Open Save Modal
        </button>
        <button
          class="primary-btn !bg-red-500 hover:!bg-red-600"
          @click="$refs.deletePopup.showPopup()"
        >
          Open Delete Modal
        </button>
      </div>

      <Popup
        ref="deletePopup"
        confirm-btn-text="Primary Button"
        cancel-btn-text="Secondary Button"
        confirm-btn-class="!bg-red-500 hover:!bg-red-600"
        @cancel="$refs.deletePopup.hidePopup()"
        @confirm="console.log('Deleted')"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">
            Any Title?
          </h2>
          <p class="text-sm max-w-xs text-center">
            Anything you want to show in the modal. This action cannot be undone.
          </p>
        </div>
      </Popup>

      <Popup
        ref="savePopup"
        confirm-btn-text="Primary Button"
        cancel-btn-text="Secondary Button"
        confirm-btn-class="primary-btn"
        @cancel="$refs.savePopup.hidePopup()"
        @confirm="console.log('Saved')"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">
            Any Title?
          </h2>
          <p class="text-sm max-w-xs text-center">
            Anything you want to show in the modal. This action cannot be undone.
          </p>
        </div>
      </Popup>
      <monaco-editor
        :value="modalCode"
        language="html"
        theme="vs-dark"
        height="200"
        class="components-code-editor"
      />
    </section>

    <!-- Tables Section -->
    <section class="mb-10">
      <h2 class="components-header">
        Table & Pagination
      </h2>
      <div class="table-wrapper">
        <table class="primary-table">
          <thead class="table-header">
            <tr>
              <th class="px-4 py-2">
                Name
              </th>
              <th class="px-4 py-2">
                Age
              </th>
              <th class="px-4 py-2">
                Country
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr class="hover:bg-gray-50 dark:hover:bg-gray-900">
              <td class="px-4 py-2">
                John
              </td>
              <td class="px-4 py-2">
                30
              </td>
              <td class="px-4 py-2">
                USA
              </td>
            </tr>
            <tr class="hover:bg-gray-50 dark:hover:bg-gray-900 rounded-bl-xl">
              <td class="px-4 py-2">
                Jane
              </td>
              <td class="px-4 py-2">
                28
              </td>
              <td class="px-4 py-2">
                UK
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-wrapper">
        <div class="flex gap-1 w-max shrink-0 items-center">
          <span class="text-gray-500 dark:text-gray-400 flex items-center gap-1">
            Showing
            <span class="font-semibold text-gray-600 dark:text-white">1</span>
            to
            <span class="font-semibold text-gray-600 dark:text-white">2</span>
            of
            <span class="font-semibold text-gray-600 dark:text-white">2</span>
          </span>

          <span class="text-gray-500 dark:text-gray-400 w-max shrink-0">· Rows per page</span>

          <select
            class="ml-2 cursor-pointer max-w-[80px] bg-gray-50 border border-gray-200 text-gray-800 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option
              :value="10"
              selected
            >
              10
            </option>
            <option :value="25">
              25
            </option>
            <option :value="50">
              50
            </option>
            <option :value="100">
              100
            </option>
            <option>All</option>
          </select>
        </div>

        <div class="sm:ml-auto pagination-btn-wrapper">
          <!-- Buttons -->
          <button class="pagination-btn-prev">
            <Icon
              icon="hugeicons:arrow-left-01"
              class="w-4 h-4"
            />
            Prev
          </button>
          <button class="pagination-btn-next">
            Next
            <Icon
              icon="hugeicons:arrow-right-01"
              class="w-4 h-4"
            />
          </button>
        </div>
      </div>
      <monaco-editor
        :value="tableCode"
        language="html"
        theme="vs-dark"
        height="200"
        class="components-code-editor"
      />
    </section>
  </div>
</template>

<script>
  import { defineComponent, ref } from 'vue'
  import MonacoEditor from 'monaco-editor-vue3'
  import { useGlobalStore } from './stores/globalStore'

  export default defineComponent({
    components: {
      MonacoEditor,
    },
    setup() {
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const showModal = ref(false)

      // Example code snippets
      const buttonCode = ref(` <div class="flex items-center gap-4 flex-wrap">
        <button class="primary-btn">Primary Button</button>
        <button class="primary-btn !bg-red-500 hover:!bg-red-600">Danger Button</button>
        <button class="gray-btn">
          <Icon icon="hugeicons:sparkles" class="w-4 h-4 text-[#262626] dark:text-white" />
          <span>Suggest Button</span>
        </button>
        <button class="gray-btn">
          <Icon icon="hugeicons:play" class="w-4 h-4 text-[#262626] dark:text-white" />
          Gray Button With Icon
        </button>
        <button class="outline-btn">Outline Button</button>
        <button class="secondary-btn">Secondary Button</button>
        <button class="cancel-btn">Cancel Button</button>
      </div>`)

      const modalCode = ref(`<div class="flex items-center gap-4">
        <button @click="$refs.savePopup.showPopup()" class="primary-btn">Open Save Modal</button>
        <button @click="$refs.deletePopup.showPopup()" class="primary-btn !bg-red-500 hover:!bg-red-600">
          Open Delete Modal
        </button>
      </div>

      <Popup
        ref="deletePopup"
        @cancel="$refs.deletePopup.hidePopup()"
        @confirm="console.log('Deleted')"
        confirm-btn-text="Primary Button"
        cancel-btn-text="Secondary Button"
        confirm-btn-class="!bg-red-500 hover:!bg-red-600"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">Any Title?</h2>
          <p class="text-sm max-w-xs text-center">
            Anything you want to show in the modal. This action cannot be undone.
          </p>
        </div>
      </Popup>

      <Popup
        ref="savePopup"
        @cancel="$refs.savePopup.hidePopup()"
        @confirm="console.log('Saved')"
        confirm-btn-text="Primary Button"
        cancel-btn-text="Secondary Button"
        confirm-btn-class="primary-btn"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">Any Title?</h2>
          <p class="text-sm max-w-xs text-center">
            Anything you want to show in the modal. This action cannot be undone.
          </p>
        </div>
      </Popup>`)

      const inputCode = ref(
        ` <div class="flex items-center gap-4 flex-wrap">
        <input type="text" placeholder="Primary input" class="primary-input max-w-[340px]" />
        <Search />
        <ResizableTextarea
          placeholder="Resizable textarea with minrows 5 and maxrows 10"
          class="w-full"
          :minRows="5"
          :maxRows="10"
        />
      </div>`
      )

      const tableCode = ref(`<div class="table-wrapper">
        <table class="primary-table">
          <thead class="table-header">
            <tr>
              <th class="px-4 py-2">Name</th>
              <th class="px-4 py-2">Age</th>
              <th class="px-4 py-2">Country</th>
            </tr>
          </thead>
           <tbody class="table-body">
            <tr class="hover:bg-gray-50 dark:hover:bg-gray-900">
              <td class="px-4 py-2">John</td>
              <td class="px-4 py-2">30</td>
              <td class="px-4 py-2">USA</td>
            </tr>
            <tr class="hover:bg-gray-50 dark:hover:bg-gray-900 rounded-bl-xl">
              <td class="px-4 py-2">Jane</td>
              <td class="px-4 py-2">28</td>
              <td class="px-4 py-2">UK</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-wrapper">
        <div class="flex gap-1 w-max shrink-0 items-center">
          <span class="text-gray-500 dark:text-gray-400 flex items-center gap-1">
            Showing
            <span class="font-semibold text-gray-600 dark:text-white">1</span>
            to
            <span class="font-semibold text-gray-600 dark:text-white">2</span>
            of
            <span class="font-semibold text-gray-600 dark:text-white">2</span>
          </span>

          <span class="text-gray-500 dark:text-gray-400 w-max shrink-0">· Rows per page</span>

          <select
            class="ml-2 cursor-pointer max-w-[80px] bg-gray-50 border border-gray-200 text-gray-800 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option :value="10" selected>10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option>All</option>
          </select>
        </div>

        <div class="sm:ml-auto pagination-btn-wrapper">
          <!-- Buttons -->
          <button class="pagination-btn-prev">
            <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
            Prev
          </button>
          <button class="pagination-btn-next">
            Next
            <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
          </button>
        </div>
      </div>`)

      return {
        showModal,
        buttonCode,
        modalCode,
        inputCode,
        tableCode,
        notify,
      }
    },
  })
</script>
