import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { Icon } from '@iconify/vue'
import ShareModal from './components/ShareModal.vue'

// https://floating-vue.starpad.dev/guide/
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import App from './App.vue'
import VueApexCharts from 'vue3-apexcharts'
import router from './router.js'
import './index.css'
import { useUserStore } from '@/stores/userStore'
import { useOrgStore } from '@/stores/orgStore'
// import 'typeface-roboto-mono'

import VueDOMPurifyHTML from 'vue-dompurify-html'
import VueClickAway from 'vue3-click-away'

// components
import Search from './components/Search.vue'
import ResizableTextarea from './components/ResizableTextarea.vue'
import RadioInput from './components/RadioInput.vue'
import Popup from './components/Popup.vue'

const pinia = createPinia()
const userStore = useUserStore(pinia)
const orgStore = useOrgStore(pinia)

const app = createApp(App)
app.component('Icon', Icon)
app.component('Search', Search)
app.component('ShareModal', ShareModal)
app.component('ResizableTextarea', ResizableTextarea)
app.component('RadioInput', RadioInput)
app.component('Popup', Popup)

router.beforeEach((to, from, next) => {
  if (!['Login', 'Register', 'Verify', 'Logout', 'Password', 'Invite', 'Share'].includes(to.name)) {
    userStore.checkLogin()
    orgStore.getOrg()
  }

  next()
})

orgStore.getGroups()

app.use(router)
app.use(pinia)
app.use(FloatingVue)
app.use(VueApexCharts)
app.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    explanation: {
      ADD_ATTR: ['target'],
    },
  },
})
app.use(VueClickAway)

app.mount('#app')
