<template>
  <svg
    :class="classList ? classList : 'w-8 h-8'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 62 60"
  >
    <defs>
      <linearGradient
        id="A"
        x1="42.046"
        y1="18.109"
        x2="42.046"
        y2="60.523"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0"
          stop-color="#00b8f1"
        />
        <stop
          offset=".22"
          stop-color="#06a5e5"
        />
        <stop
          offset=".79"
          stop-color="#1870c5"
        />
        <stop
          offset="1"
          stop-color="#1d61bc"
        />
      </linearGradient>
    </defs>
    <path
      d="M0 17.975V60h42.928l42.017-42.02H0z"
      fill="url(#A)"
      transform="matrix(.753423 0 0 .753423 0 2.625985)"
    />
    <path
      d="M39.04 30.904h-1.35v-4.932h1.35c1.802 0 3.237.593 3.237 2.436 0 1.903-1.435 2.498-3.237 2.498m-14.53 3.53c-.67.003-1.335-.114-1.963-.346l1.944-6.13h.04l1.905 6.15c-.618.22-1.27.33-1.924.33m14.17-12.476H32.55v14.58l-5.356-14.58H21.89l-4.574 12.186c-.484-3.068-3.664-4.133-6.166-4.928-1.648-.53-3.404-1.3-3.388-2.173.014-.706.943-1.36 2.774-1.264 1.234.062 2.333.16 4.485 1.207l2.138-3.71c-1.973-1.007-4.706-1.64-6.944-1.645h-.02c-2.61 0-4.782.85-6.13 2.243-.932.96-1.458 2.24-1.468 3.577-.035 1.878.657 3.21 2.105 4.277 1.225.898 2.788 1.475 4.168 1.91 1.703.523 3.093.984 3.077 1.963a1.42 1.42 0 0 1-.404.953c-.424.437-1.075.605-1.977.626-1.738.035-3.025-.235-5.078-1.448L2.59 39.494c2.114 1.2 4.503 1.836 6.934 1.845h.32c2.15-.04 3.888-.655 5.276-1.77l.225-.194-.607 1.64h5.565l.935-2.844c2.1.68 4.36.69 6.467.027l.9 2.82h9.08v-5.878h1.983c4.786 0 7.62-2.436 7.62-6.524 0-4.55-2.755-6.64-8.612-6.64"
      fill="#fff"
    />
  </svg>
</template>

<script>
  export default {
    name: 'SAPHanaIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
