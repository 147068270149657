<template>
  <div
    ref="chartContainer"
    class="chart-container will-change-transform"
  />
</template>

<script>
  import embed from 'vega-embed'
  import { useUserStore } from '@/stores/userStore'

  const renderChart = async function () {
    const userStore = useUserStore()
    if (this.vegaLiteJson) {
      try {
        const config = userStore.isDarkMode ? this.darker_config : this.light_config
        // https://github.com/vega/vega-themes
        // remove config from the vegaLiteJson
        delete this.vegaLiteJson.config

        // Add the numberFormat property to the existing config object
        config.numberFormat = ',.2~f'
        this.chart = await embed(this.$refs.chartContainer, this.vegaLiteJson, {
          actions: false,
          renderer: 'svg',
          config: config,
          // numberFormat: ",.2f",
          tooltip: {
            theme: 'dark',
          },
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  export default {
    name: 'VegaLiteChart',
    props: {
      vegaLiteJson: {
        type: Object,
        required: true,
        background: 'transparent',
        autosize: { type: 'fit', resize: true },
      },
    },
    data() {
      return {
        chart: null,
        userStore: useUserStore(),

        // more info https://altair.streamlit.app/Bar_Rounded
        darker_config: {
          font: '"Inter", monospace',
          // "background": "#ffffff",
          background: 'transparent',
          // "view": {"stroke": "transparent"},
          fieldTitle: 'verbal',
          autosize: { type: 'fit', contains: 'padding' },
          title: {
            align: 'left',
            anchor: 'start',
            color: '#ffffff',
            titleFontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            orient: 'top',
            offset: 26,
          },
          axis: {
            labelFontSize: 12,
            labelFontWeight: 400,
            labelColor: '#afb4cc',
            labelFontStyle: 'normal',
            titleFontWeight: 400,
            titleFontSize: 12,
            titleColor: '#afb4cc',
            titleFontStyle: 'normal',
            ticks: false,
            grid: false,
            // "gridColor": "rgba(18,99,230,0.1)",
            domain: false,
            // "domainWidth": 1,
            // "domainColor": "#C8C6C4",
            labelFlush: true,
            labelFlushOffset: 1,
            labelBound: false,
            labelLimit: 180,
            titlePadding: 16,
            labelPadding: 16,
            labelSeparation: 4,
            labelOverlap: true,
          },
          legend: {
            labelFontSize: 12,
            labelFontWeight: 400,
            labelColor: '#afb4cc',
            titleFontSize: 12,
            titleFontWeight: 400,
            titleFontStyle: 'normal',
            // "titleColor": "#808495",
            titleColor: '#9a9eb3',
            titlePadding: 12,
            labelPadding: 16,
            columnPadding: 8,
            rowPadding: 4,
            padding: 7,
            symbolStrokeWidth: 4,
          },
          axisQuantitative: {
            tickCount: 3,
            grid: true,
            gridColor: '#C8C6C4',
            gridDash: [1, 5],
            labelFlush: false,
            domain: false,
          },
          range: {
            category: [
              '#83c9ff',
              '#0068c9',
              '#ffabab',
              '#ff2b2b',
              '#7defa1',
              '#29b09d',
              '#ffd16a',
              '#ff8700',
              '#6d3fc0',
              '#d5dae5',
            ],
            diverging: [
              '#7d353b',
              '#bd4043',
              '#ff4b4b',
              '#ff8c8c',
              '#ffc7c7',
              '#a6dcff',
              '#60b4ff',
              '#1c83e1',
              '#0054a3',
              '#004280',
            ],
            ramp: [
              '#004280',
              '#0054a3',
              '#0068c9',
              '#1c83e1',
              '#3d9df3',
              '#60b4ff',
              '#83c9ff',
              '#a6dcff',
              '#c7ebff',
              '#e4f5ff',
            ],
            heatmap: [
              '#004280',
              '#0054a3',
              '#0068c9',
              '#1c83e1',
              '#3d9df3',
              '#60b4ff',
              '#83c9ff',
              '#a6dcff',
              '#c7ebff',
              '#e4f5ff',
            ],
          },
          view: {
            columns: 1,
            strokeWidth: 0,
            stroke: 'transparent',
            // "continuousHeight": 350,
            // "continuousWidth": 400
          },
          concat: { columns: 1 },
          facet: { columns: 1 },
          mark: { tooltip: true, color: '#83C9FF' },
          // "bar": {"binSpacing": 4, "discreteBandSize": {"band": 0.85}},
          axisDiscrete: { grid: false },
          axisXPoint: { grid: false },
          axisTemporal: { grid: false },
          axisXBand: { grid: false },
          header: {
            labelColor: '#ffffff',
            titleColor: '#ffffff',
          },
        },
        light_config: {
          font: '"Inter", monospace',
          // "background": "#ffffff",
          background: 'transparent',
          // "view": {"stroke": "transparent"},
          fieldTitle: 'verbal',
          autosize: { type: 'fit', contains: 'padding' },
          title: {
            align: 'left',
            anchor: 'start',
            color: '#000000',
            titleFontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            orient: 'top',
            offset: 26,
          },
          axis: {
            labelFontSize: 12,
            labelFontWeight: 400,
            labelColor: '#555763',
            labelFontStyle: 'normal',
            titleFontWeight: 400,
            titleFontSize: 12,
            titleColor: '#555763',
            titleFontStyle: 'normal',
            ticks: false,
            grid: false,
            // "gridColor": "rgba(18,99,230,0.1)",
            domain: false,
            // "domainWidth": 1,
            // "domainColor": "#555763",
            labelFlush: true,
            labelFlushOffset: 1,
            labelBound: false,
            labelLimit: 180,
            titlePadding: 16,
            labelPadding: 16,
            labelSeparation: 4,
            labelOverlap: true,
          },
          legend: {
            labelFontSize: 12,
            labelFontWeight: 400,
            labelColor: '#555763',
            titleFontSize: 12,
            titleFontWeight: 400,
            titleFontStyle: 'normal',
            // "titleColor": "#808495",
            titleColor: '#484a54',
            titlePadding: 12,
            labelPadding: 16,
            columnPadding: 8,
            rowPadding: 4,
            padding: 7,
            symbolStrokeWidth: 4,
          },
          axisQuantitative: {
            tickCount: 3,
            grid: true,
            gridColor: '#807c79',
            gridDash: [1, 5],
            labelFlush: false,
            domain: false,
          },
          range: {
            category: [
              '#0068c9',
              '#83c9ff',
              '#ff2b2b',
              '#ffabab',
              '#29b09d',
              '#7defa1',
              '#ff8700',
              '#ffd16a',
              '#6d3fc0',
              '#d5dae5',
            ],
            diverging: [
              '#7d353b',
              '#bd4043',
              '#ff4b4b',
              '#ff8c8c',
              '#ffc7c7',
              '#a6dcff',
              '#60b4ff',
              '#1c83e1',
              '#0054a3',
              '#004280',
            ],
            ramp: [
              '#e4f5ff',
              '#c7ebff',
              '#a6dcff',
              '#83c9ff',
              '#60b4ff',
              '#3d9df3',
              '#1c83e1',
              '#0068c9',
              '#0054a3',
              '#004280',
            ],
            heatmap: [
              '#e4f5ff',
              '#c7ebff',
              '#a6dcff',
              '#83c9ff',
              '#60b4ff',
              '#3d9df3',
              '#1c83e1',
              '#0068c9',
              '#0054a3',
              '#004280',
            ],
          },
          view: {
            columns: 1,
            strokeWidth: 0,
            stroke: 'transparent',
            // "continuousHeight": 350,
            // "continuousWidth": 400
          },
          concat: { columns: 1 },
          facet: { columns: 1 },
          mark: { tooltip: true, color: '#83C9FF' },
          // "bar": {"binSpacing": 4, "discreteBandSize": {"band": 0.85}},
          axisDiscrete: { grid: false },
          axisXPoint: { grid: false },
          axisTemporal: { grid: false },
          axisXBand: { grid: false },
        },
      }
    },
    watch: {
      vegaLiteJson: {
        handler() {
          this.renderChart()
        },
        immediate: true,
        deep: true,
      },
      'userStore.isDarkMode': {
        handler: function () {
          if (this.vegaLiteJson && this.$refs.chartContainer) {
            // re-render the chart when the parent element is visible
            if (this.$refs?.chartContainer?.closest('.chart_grand_parent').classList.contains('hidden')) return
            this.renderChart()
          }
        },
        immediate: true,
      },
      "this.$refs?.chartContainer?.closest('.chart_grand_parent').classList": {
        handler: function () {
          if (this.vegaLiteJson && this.$refs.chartContainer) {
            // re-render the chart when the parent element is visible
            if (this.$refs?.chartContainer?.closest('.chart_grand_parent').classList.contains('hidden')) return
            this.renderChart()
          }
        },
        immediate: true,
      },
    },
    mounted() {
      this.renderChart()
    },
    methods: {
      renderChart,
    },
  }
</script>
<!--  https://github.com/vega/vega-tooltip/blob/main/docs/customizing_your_tooltip.md
 -->
<style>
  .chart-container {
    background-color: transparent;
    width: 100%;
  }

  #vg-tooltip-element.vg-tooltip {
    font-size: 14px;
    border: none;
    color: white;
    font-family: 'Roboto Mono', Consolas, monospace;
    background: rgba(33, 46, 64, 0.8);
  }
</style>
