<template>
  <div class="flex items-center row">
    <div>
      <label
        for="small"
        class="block mb-1 mt-2 text-sm text-gray-900 dark:text-white"
      >Color Palette</label>
      <select
        id="small"
        v-model="selectedPaletteIndex"
        class="block w-full p-1 mb-3 text-sm text-gray-900 border border-gray-300 rounded-xl bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        @change="savePalette"
      >
        <option
          v-for="(palette, index) in palettes"
          :key="index"
          :value="index"
        >
          {{ names[index] }}
        </option>
      </select>
    </div>

    <div class="flex row ml-auto mt-5">
      <div
        v-for="(color, index) in palettes[selectedPaletteIndex]"
        :key="index"
        class="rounded w-5 h-7 border-2 border-gray-100"
        :class="{
          'border-white dark:border-black': names[selectedPaletteIndex] !== 'custom',
          'cursor-pointer border-gray-200 dark:border-gray-800 hover:border-gray-500 dark:hover:border-gray-500':
            names[selectedPaletteIndex] === 'custom' && editingColorIndex !== index,
          'cursor-pointer border-gray-900 dark:border-gray-100':
            names[selectedPaletteIndex] === 'custom' && editingColorIndex === index,
        }"
        :style="{ backgroundColor: color }"
        @click="names[selectedPaletteIndex] === 'custom' ? selectColor(index) : ''"
      />
    </div>

    <!-- Hidden Color Picker Input -->
    <input
      ref="colorPicker"
      v-model="selectedColor"
      type="color"
      class="hidden"
      @change="updateColor(editingColorIndex)"
    >
  </div>
</template>

<script>
  import axios from '@/axiosInstance'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'
  import { useGlobalStore } from '@/stores/globalStore'

  export default {
    name: 'ColorPalettePicker',
    setup() {
      const orgStore = useOrgStore()
      const { org } = storeToRefs(orgStore)
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      return {
        notify,
        org,
      }
    },
    data() {
      return {
        palettes: [
          // dot
          [
            '#eb7a2e',
            '#f7b62a',
            '#1fa5de',
            '#57bebe',
            '#7f7977',
            '#b1a898',
            '#5e4a86',
            '#8e80b9',
            '#4a4c51',
            '#8a8b94',
          ],

          // streamlit
          [
            '#0068c9',
            '#83c9ff',
            '#ff2b2b',
            '#ffabab',
            '#29b09d',
            '#7defa1',
            '#ff8700',
            '#ffd16a',
            '#6d3fc0',
            '#d5dae5',
          ],

          // tableau 10
          [
            '#4E79A7',
            '#F28E2B',
            '#E15759',
            '#76B7B2',
            '#59A14F',
            '#EDC949',
            '#AF7AA1',
            '#FF9DA7',
            '#9C755F',
            '#BAB0AB',
          ],

          // power bi default
          [
            '#118DFF',
            '#12239E',
            '#E66C37',
            '#6B007B',
            '#E044A7',
            '#744EC2',
            '#D9B300',
            '#D64550',
            '#8a8b94',
            '#4a4c51',
          ],

          // Orange-Blue Diverging
          [
            '#1c5998',
            '#1c73b1',
            '#3a87b7',
            '#67add4',
            '#7bc8e2',
            '#fdab67',
            '#fd8938',
            '#f06511',
            '#d74401',
            '#a33202',
          ],

          // Custom
          [
            '#eb7a2e',
            '#f7b62a',
            '#1fa5de',
            '#57bebe',
            '#7f7977',
            '#b1a898',
            '#5e4a86',
            '#8e80b9',
            '#4a4c51',
            '#8a8b94',
          ],
        ],
        names: ['dot', 'streamlit', 'tableau', 'powerbi', 'orange-blue', 'custom'],
        selectedPaletteIndex: 0,
        selectedColor: '',
        // showColorPicker: false,
        editingColorIndex: -1,
      }
    },
    created() {
      // check if org.color_palette is set
      if (this.org.color_palette) {
        // when set, set the selectedPaletteIndex to the index of the color_palette
        this.selectedPaletteIndex = this.palettes.findIndex(palette => {
          return palette.join('') === this.org.color_palette.join('')
        })

        // if not found, set to custom and add the color_palette to the palettes
        if (this.selectedPaletteIndex === -1) {
          this.selectedPaletteIndex = this.palettes.length - 1
          this.palettes[this.selectedPaletteIndex] = this.org.color_palette
        }
      }
    },
    methods: {
      selectColor(index) {
        this.editingColorIndex = index
        this.selectedColor = this.palettes[this.selectedPaletteIndex][index]

        console.log(this.selectedColor)
        // Ensure DOM updates before opening the color picker
        this.$nextTick(() => {
          this.$refs.colorPicker.click()
        })
      },
      updateColor(index) {
        this.palettes[this.selectedPaletteIndex][index] = this.selectedColor
        // this.showColorPicker = false;
        this.savePalette()
      },
      savePalette() {
        // call api to save palette
        const palette = this.palettes[this.selectedPaletteIndex]

        axios
          .post('/api/save_color_palette', { palette }, { withCredentials: true })
          .then(response => {
            console.log(response.data)
            this.notify.success('Saved successfully')
            this.org.color_palette = palette
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
