<template>
  <div class="flex flex-row h-full bg-white dark:bg-gray-950">
    <SideNavigation
      v-if="!isLoginPage && !hideSideNavigation"
      class="z-10"
    />
    <div class="overflow-y-auto w-full h-full pt-[56px] md:pt-0">
      <HelpMenu v-if="!hideHelp" />
      <ThemeToggle />
      <NotificationComponent />
      <router-view />
    </div>
  </div>
</template>

<script>
  import SideNavigation from '@/components/SideNavigation.vue'
  import HelpMenu from '@/components/HelpMenu.vue'
  import ThemeToggle from '@/components/ThemeToggle.vue'
  import NotificationComponent from './components/NotificationComponent.vue'

  export default {
    name: 'App',
    components: {
      SideNavigation,
      HelpMenu,
      ThemeToggle,
      NotificationComponent,
    },
    data() {
      return {
        billing_active: false,
        showBanner: true,
      }
    },
    computed: {
      isLoginPage() {
        return ['Login', 'Register', 'Verify', 'Logout', 'Password', 'Invite', 'Share'].includes(this.$route.name)
      },
      hideSideNavigation() {
        // check url paramter hideSideNavigation
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get('hideSideNavigation') === 'true'
      },
      hideHelp() {
        // check url paramter hideSideNavigation
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get('hideHelp') === 'true'
      },
    },
  }
</script>

<style>
  html,
  body {
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }

  #app {
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
    font-size: 14px;
  }

  ::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
    margin: 2px;
  }

  /* adjust width of horizontal scrollbar also to 8x */
  ::-webkit-scrollbar:horizontal {
    height: 8px;
    margin: 2px;
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Change to match Firefox's track color */
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(115, 112, 112, 0.05); /* Change to match Firefox's thumb color */
    border-radius: 6px; /* Optional: if you want rounded corners */
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(115, 112, 112, 0.4); /* Color when hovered */
  }

  ::-webkit-scrollbar-thumb:active {
    background: rgba(115, 112, 112, 0.4); /* Color when clicked or dragged */
  }

  .dark ::selection {
    background: rgba(250, 170, 30, 0.3);
  }
  ::selection {
    background: rgba(180, 83, 9, 0.2);
  }
</style>
