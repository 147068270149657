<template>
  <svg
    :class="classList ? classList : 'w-6 h-6'"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.94533 1.36349H12.3699L6.73867 18.0483C6.68079 18.2197 6.5706 18.3687 6.42362 18.4742C6.27663 18.5798 6.10025 18.6366 5.91929 18.6366H1.69763C1.56052 18.6366 1.42539 18.604 1.3034 18.5414C1.18141 18.4789 1.07607 18.3881 0.996101 18.2768C0.91613 18.1654 0.863826 18.0366 0.843514 17.901C0.823202 17.7654 0.835467 17.6269 0.879293 17.497L6.12575 1.95182C6.1836 1.7803 6.29381 1.63125 6.44084 1.52567C6.58787 1.42009 6.76432 1.3635 6.94533 1.36349Z"
      fill="url(#paint0_linear_320_686)"
    />
    <path
      d="M14.8288 12.5547H6.22669C6.14672 12.5546 6.06857 12.5786 6.00243 12.6236C5.93629 12.6685 5.88523 12.7324 5.85589 12.8067C5.82655 12.8811 5.82029 12.9627 5.83794 13.0407C5.85558 13.1187 5.89631 13.1895 5.95482 13.2441L11.4823 18.4032C11.6432 18.5534 11.8551 18.6368 12.0752 18.6368H16.9461L14.8288 12.5547Z"
      fill="#0078D4"
    />
    <path
      d="M6.94619 1.36329C6.7632 1.36259 6.58477 1.42041 6.43699 1.52832C6.2892 1.63623 6.17978 1.78857 6.12473 1.96308L0.886602 17.4827C0.839826 17.613 0.825142 17.7528 0.843789 17.89C0.862437 18.0273 0.913868 18.158 0.993732 18.2712C1.0736 18.3844 1.17954 18.4767 1.30261 18.5402C1.42568 18.6038 1.56225 18.6368 1.70077 18.6364H6.03139C6.19268 18.6076 6.34344 18.5365 6.46825 18.4303C6.59306 18.3242 6.68746 18.1868 6.74181 18.0322L7.78639 14.9537L11.5176 18.4339C11.674 18.5632 11.8701 18.6348 12.0731 18.6364H16.9258L14.7974 12.5543L8.59306 12.5558L12.3904 1.36329H6.94619Z"
      fill="url(#paint1_linear_320_686)"
    />
    <path
      d="M13.8737 1.95078C13.816 1.77954 13.7059 1.63074 13.5591 1.52534C13.4123 1.41994 13.2361 1.36326 13.0554 1.36328H7.00977C7.19048 1.36329 7.36665 1.41998 7.51345 1.52538C7.66025 1.63077 7.7703 1.77956 7.8281 1.95078L13.0748 17.4966C13.1186 17.6265 13.1309 17.7651 13.1106 17.9007C13.0903 18.0363 13.038 18.1652 12.9581 18.2766C12.8781 18.388 12.7727 18.4787 12.6507 18.5413C12.5287 18.6039 12.3936 18.6366 12.2564 18.6366H18.3023C18.4394 18.6366 18.5745 18.6039 18.6965 18.5413C18.8185 18.4786 18.9238 18.3879 19.0038 18.2765C19.0837 18.1651 19.136 18.0362 19.1563 17.9006C19.1765 17.765 19.1642 17.6265 19.1204 17.4966L13.8737 1.95078Z"
      fill="url(#paint2_linear_320_686)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_320_686"
        x1="8.92242"
        y1="2.64349"
        x2="3.28888"
        y2="19.2864"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#114A8B" />
        <stop
          offset="1"
          stop-color="#0669BC"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_320_686"
        x1="10.6831"
        y1="10.3993"
        x2="9.37993"
        y2="10.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-opacity="0.3" />
        <stop
          offset="0.071"
          stop-opacity="0.2"
        />
        <stop
          offset="0.321"
          stop-opacity="0.1"
        />
        <stop
          offset="0.623"
          stop-opacity="0.05"
        />
        <stop
          offset="1"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_320_686"
        x1="9.96539"
        y1="2.15786"
        x2="16.1493"
        y2="18.6331"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3CCBF4" />
        <stop
          offset="1"
          stop-color="#2892DF"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
  export default {
    name: 'OpenAIIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
