<template>
  <div
    v-if="(!shared_page || is_external_chat) && show_modal"
    id="shareModal"
    class="fixed top-0 left-0 right-0 !z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%)] max-h-full bg-gray-900 bg-opacity-50 dark:bg-opacity-80 flex items-center justify-center cursor-pointer"
    @click.stop="closeModal"
  >
    <div
      class="relative w-full max-w-[840px] max-h-full"
      @click.stop
    >
      <!-- Modal content -->
      <div class="relative bg-white rounded-xl shadow dark:bg-gray-800">
        <!-- Modal header -->
        <div class="flex justify-between items-center px-4 border-b primary-border">
          <div class="tab-wrapper">
            <button
              id="share-tab"
              class="primary-tab"
              :class="{
                'active-tab': activeTab === 'share',
                'inactive-tab': activeTab === 'schedule',
              }"
              type="button"
              @click="changeTab('share')"
            >
              Share
            </button>
            <button
              id="schedule-tab"
              class="primary-tab flex items-center gap-2"
              :class="{
                'active-tab': activeTab === 'schedule',
                'inactive-tab': activeTab === 'share',
              }"
              type="button"
              @click="changeTab('schedule')"
            >
              <span>Schedule</span>
              <span
                v-if="active_schedule?.job_id"
                class="relative flex h-2 w-2"
              >
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75" />
                <span class="relative inline-flex rounded-full h-2 w-2 bg-blue-500" />
              </span>
            </button>
          </div>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click.stop="closeModal"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <div v-if="activeTab === 'share'">
          <!-- Share tab body -->
          <div class="p-6 space-y-6">
            <div>
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Share Link to Chat
              </h3>
              <p class="text-14 leading-relaxed text-gray-500 dark:text-gray-400">
                Anyone with the URL will be able to view the shared chat. Messages you send after creating your link
                will be shared.
              </p>
              <p class="text-14 leading-relaxed text-gray-500 dark:text-gray-400">
                Shared conversations are similar to dashboards, you can use them to present insights to your team and
                monitor changes.
              </p>
            </div>
            <div>
              <label
                for="chat_title"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Title</label>
              <input
                id="chat_title"
                v-model="shared_chat_title"
                type="text"
                class="primary-input"
                :class="loading_save ? 'animate-pulse' : ''"
                placeholder="..."
                required
              >
            </div>
            <div class="w-full">
              <label
                for="chat_link"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Link</label>
              <div class="relative w-full">
                <input
                  id="chat_link"
                  type="text"
                  :value="sharing_chat ? 'generating public link...' : shareLink"
                  class="primary-input"
                  :class="{
                    'text-gray-500': sharing_chat,
                    'text-gray-900': !sharing_chat,
                  }"
                  disabled
                >
                <button
                  type="button"
                  class="absolute right-1.5 top-1/2 -translate-y-1/2 h-[32px] primary-btn !font-bold !rounded-12 !w-max"
                  :disabled="sharing_chat"
                  data-test="07741A54-F076-4CCB-B84C-C77C4F8C04FE"
                  @click="copyLink"
                >
                  <Icon
                    icon="hugeicons:copy-01"
                    class="h-4 w-4 font-bold"
                  />
                  <span>Copy Link</span>
                </button>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button
              type="button"
              class="cancel-btn ml-auto"
              @click="closeModal"
            >
              Cancel
            </button>
          </div>
        </div>
        <div v-else>
          <!-- Schedule tab body -->
          <h3 class="text-xl font-semibold text-gray-900 dark:text-white px-6 pt-6">
            {{ active_schedule?.job_id ? 'Update Schedule' : 'Schedule for a Later Time' }}
          </h3>
          <div class="p-6 space-y-6">
            <div class="">
              <label
                for="#schedule_channel"
                class="block mb-1"
              >Select channel</label>
              <select
                id="schedule_channel"
                v-model="schedule_channel"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                @change="
                  e => {
                    if (e.target.value === 'slack' && slack_connected && !schedule_slack_channels.length) {
                      fetchSlackChannels()
                    }
                  }
                "
              >
                <option
                  v-for="(channel, index) in schedule_channels"
                  :key="index"
                  :value="channel.value"
                >
                  {{ channel.name }}
                </option>
              </select>
              <p
                v-if="schedule_channel === 'slack' && !slack_connected"
                class="text-[12px] text-red-500"
              >
                Please connect your Slack account to schedule messages
              </p>
            </div>

            <div v-if="schedule_channel === 'email'">
              <label
                for="#schedule_emails"
                class="block mb-1"
              >Email addresses, separate with a space</label>
              <div
                class="p-2.5 pr-5 relative w-full border border-gray-300 bg-gray-50 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white focus-within:ring-blue-500 focus-within:border-blue-500 dark:focus-within:ring-blue-500 dark:focus-within:border-blue-500 rounded-xl overflow-hidden"
              >
                <div
                  v-if="schedule_emails?.length"
                  class="mb-2 flex items-center flex-wrap gap-1"
                >
                  <span
                    v-for="(email, index) in schedule_emails"
                    :key="index"
                    class="py-1 px-1.5 bg-gray-200 hover:bg-gray-300 dark:bg-gray-500 dark:hover:bg-gray-400 rounded flex items-center gap-1 w-max cursor-pointer"
                    @click="removeScheduleEmail(email)"
                  >
                    {{ email }}
                    <CloseIcon class="w-[14px]" />
                  </span>
                </div>
                <input
                  id="schedule_emails"
                  v-model="schedule_email"
                  type="email"
                  autocomplete="off"
                  :placeholder="schedule_emails.length ? 'Add another email' : 'Enter email address'"
                  class="block w-full text-sm border-0 outline-none p-0 focus:ring-0 text-gray-900 dark:text-white bg-transparent"
                  @keyup.enter="addScheduleEmail(schedule_email)"
                  @keyup.space="addScheduleEmail(schedule_email)"
                  @change="addScheduleEmail(schedule_email)"
                >
              </div>
              <p
                v-if="schedule_email_error"
                class="text-[12px] text-red-500"
              >
                Please supply at least one email address
              </p>
            </div>
            <div v-else>
              <label
                class="block mb-1"
                :class="!slack_connected ? 'opacity-50' : ''"
              >Select Slack channel</label>
              <select
                v-model="schedule_slack_channel"
                :disabled="!slack_connected"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full disabled:opacity-50 disabled:cursor-not-allowed"
                @change="
                  e => {
                    if (e.target.value) {
                      schedule_slack_error = false
                    }
                  }
                "
              >
                <option
                  v-if="fetching_slack_channels && !schedule_slack_channels.lenngth"
                  value=""
                >
                  Fetching channels...
                </option>
                <option
                  v-else-if="!schedule_slack_channels.length"
                  value=""
                >
                  No channels found
                </option>
                <option
                  v-for="channel in schedule_slack_channels"
                  :key="channel.id"
                  :value="channel.id"
                >
                  {{ channel.name }}
                </option>
              </select>
              <p
                v-if="schedule_slack_error"
                class="text-[12px] text-red-500"
              >
                Please select a Slack channel
              </p>
            </div>
            <div>
              <label class="block mb-1">Frequency</label>
              <div class="flex items-center gap-3">
                <RadioInput
                  label="Daily"
                  :checked="schedule_frequency === 'daily'"
                  class="w-1/3"
                  @toggle="value => (schedule_frequency = value ? 'daily' : schedule_frequency)"
                />
                <RadioInput
                  label="Weekly"
                  :checked="schedule_frequency === 'weekly'"
                  class="w-1/3"
                  @toggle="value => (schedule_frequency = value ? 'weekly' : schedule_frequency)"
                />
                <RadioInput
                  label="Monthly"
                  :checked="schedule_frequency === 'monthly'"
                  class="w-1/3"
                  @toggle="value => (schedule_frequency = value ? 'monthly' : schedule_frequency)"
                />
              </div>
            </div>
            <div class="grid grid-cols-3 items-center gap-3">
              <div
                v-if="schedule_frequency === 'weekly'"
                class="col-span-1"
              >
                <label class="block mb-1">Day of the week</label>
                <select
                  v-model="schedule_day"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                >
                  <option value="0">
                    Monday
                  </option>
                  <option value="1">
                    Tuesday
                  </option>
                  <option value="2">
                    Wednesday
                  </option>
                  <option value="3">
                    Thursday
                  </option>
                  <option value="4">
                    Friday
                  </option>
                  <option value="5">
                    Saturday
                  </option>
                  <option value="6">
                    Sunday
                  </option>
                </select>
              </div>
              <div
                v-if="schedule_frequency === 'monthly'"
                class="col-span-1"
              >
                <label class="block mb-1">Start date</label>
                <input
                  v-model="schedule_start_date"
                  type="date"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                >
              </div>
              <div
                :class="{
                  'col-span-2 col-start-2': schedule_frequency !== 'daily',
                  'col-span-3': schedule_frequency === 'daily',
                }"
              >
                <div class="flex mb-1 justify-between items-center">
                  <label class="block">
                    Time of the day
                    <span class="text-gray-500">(UTC)</span>
                  </label>
                  <p class="text-gray-500">
                    Your time: {{ localTimeMessage }}
                  </p>
                </div>
                <input
                  v-model="schedule_time"
                  type="time"
                  class="schedule_time_input bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-900 dark:border-gray-900 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full"
                >
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div
            class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-700 justify-end gap-5"
          >
            <button
              v-if="active_schedule?.job_id"
              type="button"
              class="text-red-600 hover:text-red-700 flex items-center gap-2 justify-center disabled:cursor-not-allowed"
              :disabled="removing_schedule"
              data-test="fff93741-9e07-4a8f-87cb-06ed35bf6a42"
              @click="deleteSchedule(active_schedule?.job_id)"
            >
              <LoadingIcon
                v-if="removing_schedule"
                class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin"
              />

              Delete Schedule
            </button>
            <button
              v-else
              type="button"
              class="cancel-btn"
              @click="closeModal"
            >
              Cancel
            </button>

            <button
              type="button"
              :disabled="
                testing_schedule ||
                  (schedule_channel === 'email' && schedule_email_error) ||
                  (schedule_channel === 'slack' && schedule_slack_error)
              "
              class="flex items-center gap-2 justify-center disabled:cursor-not-allowed text-gray-500 dark:text-gray-300"
              @click="testSchedule"
            >
              <LoadingIcon
                v-if="testing_schedule"
                class-list="w-5 h-5 text-white dark:text-gray-400 animate-spin"
              />
              Test
            </button>

            <button
              v-if="active_schedule?.job_id"
              type="button"
              class="primary-btn flex items-center gap-2 justify-center disabled:cursor-not-allowed disabled:bg-gray-300 disabled:dark:bg-gray-700 disabled:text-gray-500 disabled:dark:text-gray-300"
              :disabled="
                saving_schedule ||
                  (schedule_channel === 'email' && schedule_email_error) ||
                  (schedule_channel === 'slack' && schedule_slack_error) ||
                  !schedule_params_changed
              "
              @click="updateSchedule"
            >
              <LoadingIcon
                v-if="saving_schedule"
                class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin"
              />
              Update Schedule
            </button>
            <button
              v-else
              data-test="c97e6661-a0c7-45e9-8aec-2271feb323ca"
              type="button"
              class="primary-btn flex items-center gap-2 justify-center disabled:cursor-not-allowed"
              :disabled="
                saving_schedule ||
                  (schedule_channel === 'email' && schedule_email_error) ||
                  (schedule_channel === 'slack' && schedule_slack_error)
              "
              @click="scheduleChat(false)"
            >
              <LoadingIcon
                v-if="saving_schedule"
                class-list="w-6 h-6 text-white dark:text-gray-400 animate-spin"
              />
              Schedule
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, onMounted, ref, nextTick, watch } from 'vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import { useRoute } from 'vue-router'
  import RadioInput from './RadioInput.vue'
  import LoadingIcon from './icons/LoadingIcon.vue'
  import CloseIcon from './icons/CloseIcon.vue'
  import { useUserStore } from '@/stores/userStore'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'

  import axios from '@/axiosInstance'

  export default {
    name: 'ShareModal',

    components: {
      RadioInput,
      LoadingIcon,
      CloseIcon,
    },
    props: {
      classList: {
        type: String,
        default: '',
      },
      shared_page: {
        type: Boolean,
        default: false,
      },
      is_external_chat: {
        type: Boolean,
        default: false,
      },
      currentChatId: {
        type: String,
        default: '',
      },
      show_modal: {
        type: Boolean,
        default: false,
      },
      activeTabProp: {
        type: String,
        default: 'share',
      },
    },

    emits: ['close', 'update:title'],

    setup({ currentChatId, activeTabProp }, { emit }) {
      const route = useRoute()

      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const userStore = useUserStore()
      const { user } = storeToRefs(userStore)

      const orgStore = useOrgStore()
      const { org } = storeToRefs(orgStore)

      const messages = ref([])
      const activeTab = ref(activeTabProp)
      const shared_chat_title = ref('')
      const sharing_chat = ref(false)
      const loading_save = ref(false)
      const schedule_channel = ref('email')
      const schedule_channels = ref([
        { name: 'Email', value: 'email' },
        { name: 'Slack', value: 'slack' },
      ])
      const schedule_emails = ref([])
      const schedule_email = ref('')
      const schedule_email_error = ref(false)
      const schedule_slack_channels = ref([])
      const schedule_slack_channel = ref('')
      const schedule_slack_error = ref(false)
      const schedule_frequency = ref('daily')
      const schedule_day = ref(0)
      const schedule_start_date = ref(new Date().toISOString().slice(0, 10))
      const schedule_time = ref(new Date().toISOString().slice(11, 16))
      const active_schedule = ref(null)
      const saving_schedule = ref(false)
      const testing_schedule = ref(false)
      const removing_schedule = ref(false)
      const fetching_slack_channels = ref(false)

      function changeTab(tab) {
        activeTab.value = tab
      }

      function shareChat() {
        axios
          .post(
            '/api/share_chat',
            { chat_id: currentChatId || route.query.c, title: shared_chat_title.value || 'Chat with Dot' },
            { withCredentials: true }
          )
          .then(() => {
            loading_save.value = false
          })
          .catch(error => {
            console.log(error)
            loading_save.value = false
          })
          .finally(() => {
            sharing_chat.value = false
          })
      }

      const slack_connected = computed(() => org?.slack_team_id?.trim() !== '')
      const schedule_params_changed = computed(() => {
        if (!active_schedule.value) {
          return true
        }

        let schedule_frequency_local = 'daily'
        let schedule_time_local = new Date().toISOString().slice(11, 16)
        let schedule_day_local = 0
        let schedule_start_date_local = new Date().toISOString().slice(0, 10)
        if (active_schedule.value.cron_args.day !== '*') {
          schedule_frequency_local = 'monthly'
          schedule_time_local = `${active_schedule.value.cron_args.hour}:${active_schedule.value.cron_args.minute}`
          const date = new Date()
          const year = date.getFullYear()
          let month = date.getMonth() + 1
          month = month < 10 ? '0' + month : month
          let day = active_schedule.value.cron_args.day
          day = day < 10 ? '0' + day : day
          schedule_start_date_local = `${year}-${month}-${day}`
        } else if (active_schedule.value.cron_args.day_of_week !== '*') {
          schedule_frequency_local = 'weekly'
          schedule_time_local = `${active_schedule.value.cron_args.hour}:${active_schedule.value.cron_args.minute}`
          schedule_day_local = active_schedule.value.cron_args.day_of_week
        } else {
          schedule_frequency_local = 'daily'
          schedule_time_local = `${active_schedule.value.cron_args.hour}:${active_schedule.value.cron_args.minute}`
        }

        return (
          (schedule_channel.value === 'email' && active_schedule.value.type !== 'email') ||
          (schedule_channel.value === 'slack' && active_schedule.value.type !== 'slack') ||
          active_schedule.value.recipients?.length !== schedule_emails.value.length ||
          (active_schedule.value.type === 'email' &&
            active_schedule.value.recipients?.some(email => !schedule_emails.value.includes(email))) ||
          (active_schedule.value.type === 'slack' &&
            active_schedule.value.recipients?.some(channel => channel !== schedule_slack_channel.value)) ||
          schedule_frequency_local !== schedule_frequency.value ||
          schedule_time_local !== schedule_time.value ||
          schedule_start_date_local !== schedule_start_date.value ||
          schedule_day_local !== schedule_day.value
        )
      })

      const localTimeMessage = computed(() => {
        const utcDate = schedule_time.value ? new Date(`2000-01-01T${schedule_time.value}:00Z`) : new Date()

        // Convert UTC date to local date
        const localDate = new Date(
          utcDate.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })
        )

        // Get local time components
        const localHours = String(localDate.getHours()).padStart(2, '0')
        const localMinutes = String(localDate.getMinutes()).padStart(2, '0')

        // Get the local time zone offset in hours (including DST)
        const timeZoneOffset = -localDate.getTimezoneOffset() / 60
        const timeZoneOffsetString = `UTC${timeZoneOffset >= 0 ? '+' : ''}${timeZoneOffset}`

        // Format the local time and time zone message
        return `${localHours}:${localMinutes} (${timeZoneOffsetString})`
      })

      function addScheduleEmail(emailString, validate = false) {
        const validateEmail = email => {
          // Use a more robust regex for email validation
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
        }

        const emails = emailString
          .split(/[\s,;\n]+/) // Split on spaces, commas, semicolons, and newlines
          .map(email => email.trim())
          .filter(email => validateEmail(email)) // Validate and filter

        if (!validate) {
          schedule_emails.value = [...new Set([...schedule_emails.value, ...emails])] // Combine and remove duplicates
          schedule_email.value = '' // Clear the input field
        } else {
          return emails
        }

        schedule_email_error.value = false
      }

      function removeScheduleEmail(email) {
        schedule_emails.value = schedule_emails.value.filter(e => e !== email)

        if (schedule_emails.value.length === 0) {
          schedule_email_error.value = true
        }
      }

      function scheduleChat(update = false) {
        let chat_id = currentChatId || route.query.c

        let cron_args = {
          year: '*',
          month: '*',
          day: '*',
          week: '*',
          day_of_week: '*',
          hour: '*',
          minute: '*',
          second: '*',
        }

        let recipients = []
        let type = 'email'

        if (schedule_channel.value === 'email') {
          schedule_slack_error.value = false
          if (schedule_emails.value.length === 0 && !schedule_email.value) {
            schedule_email_error.value = true
            return
          } else {
            schedule_email_error.value = false
            if (schedule_emails.value.length === 0) {
              schedule_emails.value = addScheduleEmail(schedule_email.value, true)
              schedule_email.value = ''
            }
          }
          recipients = schedule_emails.value
        } else {
          schedule_email_error.value = false
          if (!slack_connected.value) {
            notify.error('Please connect your Slack account first')
            return
          }

          if (!schedule_slack_channel.value) {
            schedule_slack_error.value = true
            return
          } else {
            schedule_slack_error.value = false
          }

          recipients = [schedule_slack_channel.value]
          type = 'slack'
        }

        if (schedule_frequency.value === 'daily') {
          cron_args.hour = schedule_time.value.split(':')[0]
          cron_args.minute = schedule_time.value.split(':')[1]
          cron_args.second = '0'
        } else if (schedule_frequency.value === 'weekly') {
          cron_args.day_of_week = schedule_day.value
          cron_args.hour = schedule_time.value.split(':')[0]
          cron_args.minute = schedule_time.value.split(':')[1]
          cron_args.hour = schedule_time.value.split(':')[0]
          cron_args.minute = schedule_time.value.split(':')[1]
          cron_args.second = '0'
        } else if (schedule_frequency.value === 'monthly') {
          cron_args.month = schedule_start_date.value.split('-')[1]
          cron_args.day = schedule_start_date.value.split('-')[2]
          cron_args.hour = schedule_time.value.split(':')[0]
          cron_args.minute = schedule_time.value.split(':')[1]
          cron_args.second = '0'
        }

        saving_schedule.value = true

        axios
          .post('/api/add_job', { cron_args, chat_id, recipients, type }, { withCredentials: true })
          .then(() => {
            saving_schedule.value = false

            axios.get('/api/c2/' + chat_id, { withCredentials: true }).then(response => {
              active_schedule.value = response.data.schedules ? response.data.schedules[0] : null
              if (response.data.schedules) {
                setActiveSchedule(response.data.schedules[0])
              }
            })

            if (update) {
              notify.success('Schedule updated successfully')
            } else {
              notify.success('Chat scheduled successfully')
            }
          })
          .catch(error => {
            console.log(error)
            notify.error('Could not schedule chat')
            saving_schedule.value = false
          })
      }

      function deleteSchedule(job_id, update = false) {
        let chat_id = currentChatId || route.query.c
        removing_schedule.value = true
        axios
          .post('/api/remove_job', { job_id, chat_id }, { withCredentials: true })
          .then(() => {
            removing_schedule.value = false

            if (!update) {
              notify.success('Chat unscheduled successfully')
              axios.get('/api/c2/' + chat_id, { withCredentials: true }).then(response => {
                active_schedule.value = response.data.schedules ? response.data.schedules[0] : null
                resetSchedule()
              })
            }
          })
          .catch(error => {
            console.log(error)
            removing_schedule.value = false
            if (!update) {
              notify.error('Could not unschedule chat')
            }
          })
      }

      async function handleDeleteSchedule(job_id) {
        try {
          let chat_id = currentChatId || route.query.c
          const res = await axios.post('/api/remove_job', { chat_id, job_id }, { withCredentials: true })

          return res.data
        } catch (error) {
          console.log(error)
          return false
        }
      }

      function testSchedule() {
        let chat_id = currentChatId || route.query.c

        let recipients = []
        let type = 'email'

        if (schedule_channel.value === 'email') {
          schedule_slack_error.value = false
          if (schedule_emails.value.length === 0 && !schedule_email.value) {
            schedule_email_error.value = true
            return
          } else {
            schedule_email_error.value = false
            if (schedule_emails.value.length === 0) {
              schedule_emails.value.push(schedule_email)
              schedule_email.value = ''
            }
          }
          recipients = schedule_emails.value
        } else {
          schedule_email_error.value = false
          if (!slack_connected.value) {
            notify.error('Please connect your Slack account first')
            return
          }

          if (!schedule_slack_channel.value) {
            schedule_slack_error.value = true
            return
          } else {
            schedule_slack_error.value = false
          }

          recipients = [schedule_slack_channel.value]
          type = 'slack'
        }

        testing_schedule.value = true

        axios
          .post(
            '/api/test_job',
            {
              chat_id,
              recipients,
              type,
            },
            { withCredentials: true }
          )
          .then(() => {
            notify.success('Test email sent successfully')
          })
          .catch(error => {
            console.log(error)
            notify.error('Could not send test email')
          })
          .finally(() => {
            testing_schedule.value = false
          })
      }

      function updateSchedule() {
        if (!schedule_params_changed.value) {
          return
        }

        handleDeleteSchedule(active_schedule.value.job_id).then(res => {
          if (res) {
            scheduleChat(true)
          } else {
            notify.error('Could not update schedule')
          }
        })
      }

      function resetSchedule() {
        schedule_emails.value = []
        schedule_email.value = ''
        schedule_slack_channel.value = ''
        schedule_channel.value = 'email'
        schedule_frequency.value = 'daily'
        schedule_time.value = new Date().toISOString().slice(11, 16)
        schedule_day.value = 0
        schedule_start_date.value = new Date().toISOString().slice(0, 10)
      }

      watch(active_schedule, value => {
        if (value) {
          setActiveSchedule(value)
        } else {
          resetSchedule()
        }
      })

      function setActiveSchedule(value) {
        if (!value || !value.cron_args) {
          resetSchedule()
          return
        }

        if (value.cron_args.day !== '*') {
          schedule_frequency.value = 'monthly'
          schedule_time.value = `${value.cron_args.hour}:${value.cron_args.minute}`
          const date = new Date()
          const year = value.cron_args.year !== '*' ? value.cron_args.year : date.getFullYear()
          let month = value.cron_args.month !== '*' ? value.cron_args.month : date.getMonth() + 1
          month = value.cron_args.month === '*' && month < 10 ? '0' + month : month
          let day = value.cron_args.day
          day = value.cron_args.day === '*' && day < 10 ? '0' + day : day
          schedule_start_date.value = `${year}-${month}-${day}`
        } else if (value.cron_args.day_of_week !== '*') {
          schedule_frequency.value = 'weekly'
          schedule_time.value = `${value.cron_args.hour}:${value.cron_args.minute}`
          schedule_day.value = value.cron_args.day_of_week
        } else {
          schedule_frequency.value = 'daily'
          schedule_time.value = `${value.cron_args.hour}:${value.cron_args.minute}`
        }
        schedule_channel.value = value.type || 'email'
        if (value.type === 'slack') {
          schedule_slack_channel.value = value.recipients[0]
        } else {
          schedule_emails.value = value.recipients
        }
      }

      const shareLink = computed(() => {
        const org_id = user.value.org_id
        return `${window.location.origin}/share/${currentChatId || route.query.c}?org_id=${org_id}`
      })

      function copyLink() {
        // copy link to clipboard
        navigator.clipboard.writeText(shareLink.value).then(
          () => {
            console.log('Text copied to clipboard: ' + shareLink.value)
            notify.success('Link copied to clipboard')

            // close share modal if copy was successful
            closeModal()
          },
          err => {
            console.error('Could not copy text: ', err)
            notify.error('Could not copy link to clipboard')
          }
        )
      }

      function fetchConversation(chat_id) {
        sharing_chat.value = true
        axios
          .get('/api/c2/' + chat_id, { withCredentials: true })
          .then(response => {
            active_schedule.value = response.data.schedules ? response.data.schedules[0] : null

            if (response.data && response.data.messages) {
              messages.value = response.data.messages.map(message => {
                if (message.role === 'assistant' && message.json_chart?.$schema) {
                  return {
                    ...message,
                    json_chart: {
                      ...message.json_chart,
                      $schema: message.json_chart.$schema.replace('v5.8.0', 'v5.6.1').replace('v5.17.0', 'v5.6.1'),
                    },
                  }
                }
                return message
              })
              if (response.data.title) {
                shared_chat_title.value = response.data.title
                setTimeout(() => {
                  nextTick(() => {
                    shareChat()
                  })
                }, 500)
              } else {
                suggestTitle()
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            sharing_chat.value = false
          })
      }

      function suggestTitle() {
        if (!shared_chat_title.value || shared_chat_title.value === 'Chat with Dot.') {
          sharing_chat.value = true
          // concatentate all user messages
          const user_messages = messages.value
            .filter(message => message.role === 'user')
            .map(message => message.content)
            .join('\n')
          axios
            .post('/api/suggest_title', { user_messages }, { withCredentials: true })
            .then(response => {
              shared_chat_title.value = response.data
              emit('update:title', response.data)
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              sharing_chat.value = false
              setTimeout(() => {
                nextTick(() => {
                  shareChat()
                })
              }, 500)
            })
        } else {
          shareChat()
        }
      }

      async function fetchSlackChannels() {
        if (!slack_connected.value || schedule_slack_channels.value.length > 0) return

        fetching_slack_channels.value = true

        try {
          const response = await axios.get('/api/get_slack_channels', { withCredentials: true })

          if (response.data) {
            schedule_slack_channels.value = response.data
          }
        } catch (error) {
          console.log(error)
        } finally {
          fetching_slack_channels.value = false
        }
      }

      function closeModal() {
        emit('close')
      }

      onMounted(() => {
        fetchConversation(currentChatId || route.query.c)
        if (slack_connected.value && !schedule_slack_channels.value.length) {
          fetchSlackChannels()
        }
      })

      return {
        activeTab,
        shared_chat_title,
        sharing_chat,
        loading_save,
        schedule_channel,
        schedule_channels,
        schedule_emails,
        schedule_email,
        schedule_email_error,
        schedule_slack_channels,
        schedule_slack_channel,
        schedule_slack_error,
        schedule_frequency,
        schedule_day,
        schedule_start_date,
        schedule_time,
        active_schedule,
        saving_schedule,
        removing_schedule,
        testing_schedule,
        fetching_slack_channels,
        changeTab,
        scheduleChat,
        deleteSchedule,
        testSchedule,
        updateSchedule,
        closeModal,
        copyLink,
        shareLink,
        localTimeMessage,
        schedule_params_changed,
        addScheduleEmail,
        removeScheduleEmail,
        slack_connected,
        fetchSlackChannels,
      }
    },
  }
</script>
