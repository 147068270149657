<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    :class="classList ? classList : 'w-6 h-6'"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3"
    />
  </svg>
</template>

<script>
  export default {
    name: 'LongArrowDownIcon',
    props: {
      classList: {
        type: String,
        default: '',
      },
    },
  }
</script>
