<template>
  <ConnectionWrapper
    connection-name="File Upload"
    :connection-is-active="false"
  >
    <template #icon>
      <Icon
        icon="hugeicons:upload-04"
        class="w-6 h-6"
      />
    </template>
    <div class="flex flex-col gap-4">
      <div
        v-if="!uploading_files"
        class="min-h-[200px] border primary-border rounded-xl flex flex-col gap-4 items-center justify-center cursor-pointer"
        :class="{ 'border-dashed border-primary-200 bg-white shadow-lg': is_dropzone_active }"
        @dragover.prevent="is_dropzone_active = true"
        @dragleave.prevent="is_dropzone_active = false"
        @drop.prevent="handleDrop"
        @click="triggerFileInput"
      >
        <Icon
          icon="mdi:cloud-upload-outline"
          class="w-12 h-12 text-gray-500 dark:text-gray-400"
        />
        <p class="text-gray-500 dark:text-gray-400 text-14 w-full max-w-[300px] text-center mx-auto">
          Drop your .csv and .xlsx files here or click to upload (max 100MB)
        </p>
        <input
          ref="fileInput"
          type="file"
          multiple
          hidden
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel sheet.macroEnabled.12, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.oasis.opendocument.spreadsheet, application/vnd.google-apps.spreadsheet, text/plain"
          @change="handleFiles"
        >
      </div>
      <div
        v-else
        class="min-h-[200px] border primary-border rounded-xl flex flex-col gap-4 items-center justify-center cursor-pointer"
      >
        <Icon
          icon="mdi:cloud-upload-outline"
          class="w-12 h-12 text-gray-500 dark:text-gray-400 animate-pulse"
        />
        <p class="text-gray-500 dark:text-gray-400 text-14 w-full max-w-[300px] text-center mx-auto">
          Uploading files...
        </p>
      </div>
      <div
        v-if="uploaded_files.length > 0"
        class="flex mt-4 flex-col gap-2"
      >
        <div class="text-gray-500 dark:text-gray-400 text-14">
          Uploaded files
        </div>
        <div class="flex flex-col gap-2">
          <div
            v-for="file in uploaded_files"
            :key="file.id"
            class="flex items-center justify-between bg-white dark:bg-gray-950 border border-gray-100 dark:border-gray-800 rounded-xl p-4"
          >
            <div class="flex items-center gap-2">
              <Icon
                icon="mdi:file-document-outline"
                class="w-6 h-6 text-gray-500 dark:text-gray-400 shrink-0"
              />
              <div class="flex flex-col max-w-[250px]">
                <p
                  v-tooltip="file.name"
                  class="text-gray-800 dark:text-gray-200 text-16 w-full truncate font-mono font-medium"
                >
                  {{ file.name }}
                </p>
                <div class="text-gray-500 dark:text-gray-400 text-14 flex items-center gap-2 w-max">
                  <span>{{ file.size }}</span>
                  <span class="bg-gray-400 dark:bg-gray-400 h-1 w-1 rounded-full" />
                  <span>{{ file.uploaded_at }}</span>
                </div>
              </div>
            </div>
            <button
              type="button"
              class="w-max shrink-0"
              :disabled="deleting_file && active_delete_file.name === file.name"
              @click="
                () => {
                  $refs.deletePopup.showPopup()
                  active_delete_file = file
                }
              "
            >
              <LoadingIcon
                v-if="deleting_file && active_delete_file.name === file.name"
                class="!w-6 !h-6 text-gray-500 dark:text-gray-400 animate-spin"
              />
              <Icon
                v-else
                icon="mdi:trash-can-outline"
                class="w-6 h-6 text-gray-500 dark:text-gray-400 hover:text-red-600 dark:hover:text-red-500"
              />
            </button>
          </div>
        </div>
      </div>
      <Popup
        ref="deletePopup"
        confirm-btn-text="Yes, delete"
        cancel-btn-text="Cancel"
        confirm-btn-class="!bg-red-500 hover:!bg-red-600"
        @cancel="
          () => {
            $refs.deletePopup.hidePopup()
            active_delete_file = null
          }
        "
        @confirm="deleteFile()"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">
            Delete File
          </h2>
          <p class="text-sm max-w-xs text-center">
            Are you sure you want to delete this {{ active_delete_file?.name }}? This action cannot be undone.
          </p>
        </div>
      </Popup>
    </div>
  </ConnectionWrapper>
</template>

<script setup>
  import ConnectionWrapper from './ConnectionWrapper.vue'
  import { ref, onMounted } from 'vue'
  import { useGlobalStore } from '@/stores/globalStore'
  import axios from '@/axiosInstance'
  import { Icon } from '@iconify/vue'
  import Popup from '../Popup.vue'
  import { formatDistanceToNow } from 'date-fns'
  import LoadingIcon from '../icons/LoadingIcon.vue'
  import isValidFileType from '@/hooks/isValidFileType'

  const globalStore = useGlobalStore()
  const notify = globalStore.notify

  // states
  const uploading_files = ref(false)
  const is_dropzone_active = ref(false)
  const uploaded_files = ref([])
  const active_delete_file = ref(null)
  const deleting_file = ref(false)

  const fileInput = ref(null)
  const deletePopup = ref(null)

  const handleDrop = event => {
    try {
      is_dropzone_active.value = false

      const files = Array.from(event.dataTransfer?.files || [])

      const validFiles = [...files].filter(file => {
        return isValidFileType(file, notify)
      })

      if (!validFiles.length) return

      uploadFiles(validFiles)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFiles = e => {
    try {
      const files = Array.from(e.target.files)

      const validFiles = files.filter(file => {
        return isValidFileType(file, notify)
      })

      if (!validFiles.length) return

      uploadFiles(validFiles)
    } catch (error) {
      console.log(error)
    }
  }

  const triggerFileInput = () => {
    fileInput.value?.click()
  }

  const uploadFiles = files => {
    try {
      uploading_files.value = true
      const formData = new FormData()
      files.forEach(file => {
        formData.append('files', file)
      })

      formData.append('is_private', false)

      axios
        .post('/api/upload_files', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          notify.success('Files uploaded successfully')
        })
        .catch(error => {
          notify.error(error?.response?.data?.detail || 'Error uploading files')
        })
        .finally(() => {
          uploading_files.value = false
          getUploadedFiles()
        })
    } catch (error) {
      console.log(error)
    }
  }

  function getUploadedFiles() {
    axios
      .get('/api/list_files')
      .then(response => {
        uploaded_files.value = response.data.map(file => {
          return {
            name: file.filename,
            size: `${(file.size / 1024 / 1024)?.toFixed(2)} MB`,
            uploaded_at: formatDistanceToNow(new Date(file.last_modified * 1000), { addSuffix: true }),
            id: file.filename,
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  const deleteFile = () => {
    deleting_file.value = true
    const fileToDelete = active_delete_file.value

    axios
      .delete('/api/delete_file', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { filename: fileToDelete.name },
      })
      .then(() => {
        notify.success('File deleted successfully')
      })
      .catch(error => {
        console.log(error)

        notify.error(error?.response?.data?.detail || 'Error deleting file')
      })
      .finally(() => {
        deleting_file.value = false
        active_delete_file.value = null
        deletePopup.value.hidePopup()
        getUploadedFiles()
      })
  }

  onMounted(() => {
    getUploadedFiles()
  })
</script>
