<template>
  <div class="w-full max-w-[340px]">
    <label
      :for="id"
      class="sr-only"
    >Search</label>
    <div class="relative">
      <Icon
        icon="heroicons-outline:search"
        class="absolute top-1/2 transform -translate-y-1/2 left-3 text-[#17191A] dark:text-white w-4 h-2/4"
      />
      <input
        :id="id"
        type="text"
        :value="modelValue"
        v-bind="$attrs"
        class="primary-input !pl-8"
        :placeholder="placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
      >
    </div>
  </div>
</template>

<script>
  import { Icon } from '@iconify/vue'

  export default {
    name: 'Search',
    components: {
      Icon,
    },
    props: {
      modelValue: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: 'Search..',
      },
      id: {
        type: String,
        default: 'table-search',
      },
    },
    emits: ['update:modelValue'],
  }
</script>
