<template>
  <div>
    <div v-if="isMobileDevice" class="px-[24px] pb-12 bg-white text-dark-text dark:text-white dark:bg-gray-950 w-full">
      Please switch to a bigger device to view the evaluation page.
    </div>
    <div v-else id="profile" class="py-4" role="tabpanel" aria-labelledby="connection-tab">
      <h3 class="text-lg font-medium text-gray-900 dark:text-white text-left">Evaluate Dot</h3>
      <p class="text-sm text-gray-500 dark:text-gray-400 text-left">
        Don't trust it. Test it.
        <br />
        Make sure Dot understands your business and data model well enough to reliably answer questions.
      </p>
      <Popup
        ref="deletePopup"
        confirm-btn-text="Yes, delete"
        cancel-btn-text="Cancel"
        confirm-btn-class="!bg-red-500 hover:!bg-red-600"
        @cancel="$refs.deletePopup.hidePopup()"
        @confirm="deleteSelectedQuestions()"
      >
        <div class="mb-4 text-center text-gray-800 dark:text-gray-200">
          <h2 class="text-xl font-bold mb-2">Delete Questions</h2>
          <p class="text-sm max-w-xs text-center">
            Are you sure you want to delete the selected questions? This action cannot be undone.
          </p>
        </div>
      </Popup>
      <div class="mt-4">
        <div class="flex items-center justify-between">
          <Search
            :model-value="search_questions"
            placeholder="Search..."
            @update:model-value="(search_questions = $event), (page = 1)"
          />
          <div class="flex items-center gap-4 ml-auto">
            <button
              type="button"
              data-test="5f540966-5189-4d87-87d0-e575927b1d3a"
              data-modal-target="addQuestionsModal"
              data-modal-toggle="addQuestionsModal"
              class="gray-btn"
              :disabled="savingQuestions"
            >
              <LoadingIcon v-if="savingQuestions" class="inline w-4 text-gray-500 dark:text-gray-400 animate-spin" />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="w-4 h-4 mr-1"
              >
                <path
                  d="M8.75 3.75a.75.75 0 0 0-1.5 0v3.5h-3.5a.75.75 0 0 0 0 1.5h3.5v3.5a.75.75 0 0 0 1.5 0v-3.5h3.5a.75.75 0 0 0 0-1.5h-3.5v-3.5Z"
                />
              </svg>

              Add Questions
            </button>

            <button
              id="run-selected-questions"
              type="button"
              class="primary-btn"
              :disabled="selectedQuestions.length === 0 || questionIsRunning"
              @click="runSelectedQuestions"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 mr-1">
                <path
                  d="M6.3 2.84A1.5 1.5 0 0 0 4 4.11v11.78a1.5 1.5 0 0 0 2.3 1.27l9.344-5.891a1.5 1.5 0 0 0 0-2.538L6.3 2.841Z"
                />
              </svg>
              Run Selected {{ selectedQuestions.length > 0 ? `(${selectedQuestions.length})` : '' }}
            </button>
            <button
              v-if="selectedQuestions.length > 0"
              v-tooltip="deletingQuestion ? 'Deleting..' : 'Delete selected questions'"
              type="button"
              class="w-max"
              :disabled="deletingQuestion || selectedQuestions.length === 0"
              @click="$refs.deletePopup.showPopup()"
            >
              <LoadingIcon v-if="deletingQuestion" class="inline w-4 text-gray-500 dark:text-gray-400 animate-spin" />
              <TrashIcon v-else class="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-red-600" />
            </button>
          </div>
        </div>

        <div class="table-wrapper">
          <table class="primary-table">
            <thead class="table-header">
              <tr>
                <th scope="col" class="p-2">
                  <div class="flex items-center">
                    <input
                      id="checkbox-table-all"
                      type="checkbox"
                      :checked="
                        selectedQuestions.length === filteredQuestionItems.length && filteredQuestionItems.length > 0
                      "
                      class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      @click="toggleAll"
                    />
                    <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                  </div>
                </th>
                <th
                  v-tooltip="sortNumberTooltip"
                  scope="col"
                  class="w-max px-2.5 py-3 cursor-pointer"
                  @click="sortByNumber"
                >
                  <div class="text-right">No.</div>
                </th>
                <th scope="col" class="px-2.5 py-3">
                  <div class="flex items-center">Question</div>
                </th>
                <th
                  v-tooltip="sortTooltip"
                  scope="col"
                  class="px-3 py-3 text-center cursor-pointer"
                  @click="switchStatus"
                >
                  Status
                </th>
                <th scope="col" class="px-2.5 py-3">Comment</th>
                <th scope="col" class="px-3 py-3" />
              </tr>
            </thead>
            <tbody class="table-body">
              <tr
                v-for="item in filteredQuestionItems"
                :key="item.id"
                class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 cursor-pointer"
                :class="{
                  'rounded-bl-xl': item.id === filteredQuestionItems[filteredQuestionItems.length - 1].id,
                }"
                @click.stop="openDrawerChat(item)"
              >
                <td class="w-4 p-2">
                  <div class="flex items-center" @click.stop>
                    <input
                      id="checkbox-table-search-1"
                      data-test="checkbox-table"
                      type="checkbox"
                      :checked="selectedQuestions.includes(item.id)"
                      class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      @click="toggle(item)"
                    />
                    <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                  </div>
                </td>
                <td class="px-2.5 py-0 w-5 text-xs text-right">
                  <span class="text-xs text-gray-500 dark:text-gray-400">{{ item.question_number }}</span>
                </td>
                <td
                  class="px-0 py-0 w-full text-xs block truncate font-medium whitespace-nowrap text-gray-900 dark:text-white"
                >
                  <textarea
                    id="message"
                    ref="questionInput"
                    v-model="item.question_text"
                    rows="1"
                    class="block p-2.5 w-full text-sm text-gray-900 border border-transparent focus:bg-gray-50 dark:focus:bg-gray-800 resize-none bg-transparent dark:placeholder-gray-400 dark:text-white h-auto max-h-20 overflow-y-auto box-border hide-scrollbar show-scrollbar"
                    placeholder="Type a question.."
                    @input="autoResize"
                    @change="saveQuestion(item)"
                    @click.stop
                  />
                </td>
                <td class="w-[15%] max-w-max">
                  <!-- Status badge -->
                  <router-link
                    :to="{ path: '/evaluation/question', query: { c: item.run_id } }"
                    class="text-sm font-medium px-2 py-2.5 text-center block w-max h-full mx-auto"
                  >
                    <LoadingIcon
                      v-if="item.status === 'running'"
                      class="inline !w-3 text-gray-500 dark:text-gray-400 animate-spin"
                    />

                    <span
                      :class="{
                        'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': item.status === 'pass',
                        'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': item.status === 'fail',
                        'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300': item.status === 'notrun',
                        'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300': ['unclear'].includes(
                          item.status
                        ),
                        'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300': ![
                          'pass',
                          'fail',
                          'unclear',
                          'notrun',
                        ].includes(item.status),
                      }"
                      class="px-2.5 py-1 rounded-full max-w-sm"
                    >
                      {{
                        item.status === 'pass'
                          ? 'Correct'
                          : item.status === 'fail'
                            ? 'Incorrect'
                            : item.status === 'unclear'
                              ? 'Clarification Needed'
                              : item.status === 'notrun'
                                ? 'Not Run'
                                : item.status === 'running'
                                  ? 'Running..'
                                  : item.status === 'ready'
                                    ? 'Run Finished'
                                    : 'Loading..'
                      }}
                    </span>
                  </router-link>
                </td>
                <td class="px-0 py-0 w-[25%]">
                  <textarea
                    id="message"
                    ref="commentInput"
                    v-model="item.comment"
                    rows="1"
                    :disabled="!item.status || item.status === 'notrun' || item.status === 'running'"
                    class="block p-2.5 w-full text-sm text-gray-900 border border-transparent focus:bg-gray-50 dark:focus:bg-gray-800 resize-none bg-transparent dark:placeholder-gray-400 dark:text-white h-auto max-h-20 overflow-y-auto box-border hide-scrollbar show-scrollbar"
                    placeholder=""
                    @input="autoResize"
                    @change="sendQuestionRunFeedback(item, true)"
                    @click.stop
                  />
                </td>
                <td class="w-5 text-xs" data-test="expand-question">
                  <router-link
                    v-if="item.status !== 'notrun' && item.status"
                    :to="{ path: '/evaluation/question', query: { c: item.run_id } }"
                    class="px-2 py-2.5 block w-full h-full"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                      <path
                        fill-rule="evenodd"
                        d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination-wrapper">
          <!-- Help text -->
          <div class="flex items-center gap-1">
            <span class="text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1">
              Showing
              <span class="font-semibold text-gray-600 dark:text-white">
                {{ filteredQuestionItems.length > 0 ? (page - 1) * perPage + 1 : 0 }}
              </span>
              to
              <span class="font-semibold text-gray-600 dark:text-white">
                {{ shownQuestions }}
              </span>
              of
              <span class="font-semibold text-gray-600 dark:text-white">{{ allQuestions }}</span>
            </span>
            <span class="text-gray-500 dark:text-gray-400 w-max shrink-0">· Rows per page</span>

            <select
              id="perPage_draawer"
              v-model="perPage"
              class="ml-2 cursor-pointer max-w-[80px] bg-gray-50 border border-gray-200 text-gray-800 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              @change="page = 1"
            >
              <option :value="10" selected>10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="100">100</option>
              <option :value="500">500</option>
            </select>
          </div>

          <div class="sm:ml-auto pagination-btn-wrapper">
            <!-- Buttons -->
            <button class="pagination-btn-prev" @click="page = Math.max(page - 1, 1)">
              <Icon icon="hugeicons:arrow-left-01" class="w-4 h-4" />
              Prev
            </button>
            <button
              class="pagination-btn-next"
              @click="page = Math.min(page + 1, Math.max(Math.ceil(questions.length / perPage), 1))"
            >
              Next
              <Icon icon="hugeicons:arrow-right-01" class="w-4 h-4" />
            </button>
          </div>
        </div>
        <!-- Table for data end -->

        <!-- Summary table start -->
        <div class="mt-10">
          <h3 class="text-lg font-medium text-gray-900 dark:text-white mb-3">Run History</h3>
          <div class="table-wrapper">
            <table class="primary-table">
              <thead class="table-header">
                <tr>
                  <th scope="col" class="px-3 py-3 text-center" />
                  <th scope="col" class="px-3 py-3 text-center">Run</th>
                  <th scope="col" class="px-3 py-3 text-green-800 dark:text-green-300">Correct</th>
                  <th scope="col" class="px-3 py-3 dark:text-yellow-300 text-yellow-800 whitespace-nowrap">
                    Clarification Needed
                  </th>
                  <th scope="col" class="px-3 py-3 text-red-800 dark:text-red-300">Incorrect</th>
                  <th scope="col" class="px-3 py-3 text-center">Total Questions</th>
                  <th scope="col" class="px-3 py-3 text-center" />
                </tr>
              </thead>
              <tbody class="table-body">
                <tr
                  v-for="item in summary"
                  :key="item.run"
                  class="bg-white border-b dark:bg-gray-950 dark:border-gray-800 hover:bg-gray-50 dark:hover:bg-gray-900 cursor-pointer"
                  :class="{
                    'rounded-bl-xl': item.run === summary[summary.length - 1].run,
                  }"
                  @click="openRun(item)"
                >
                  <td class="text-center p-2">
                    <input
                      :checked="$route.query.timestamp === item.timestamp"
                      type="radio"
                      value=""
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td class="text-center p-2">
                    {{ item.run }}
                  </td>
                  <td>
                    <div class="p-2 flex items-center gap-2">
                      <span class="text-green-800 dark:text-green-300 font-medium">
                        {{ item.pass.value }}
                      </span>
                      <span class="text-xs text-gray-500 dark:text-gray-400">
                        {{ item.pass.percentage }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="p-2 flex items-center gap-2">
                      <span class="text-yellow-800 dark:text-yellow-300 font-medium">
                        {{ item.unclear.value }}
                      </span>
                      <span class="text-xs text-gray-500 dark:text-gray-400">
                        {{ item.unclear.percentage }}
                      </span>
                    </div>
                  </td>

                  <td>
                    <div class="p-2 flex items-center gap-2">
                      <span class="text-red-800 dark:text-red-300 font-medium">
                        {{ item.fail.value }}
                      </span>
                      <span class="text-xs text-gray-500 dark:text-gray-400">
                        {{ item.fail.percentage }}
                      </span>
                    </div>
                  </td>

                  <td class="text-center p-2">
                    {{ item.total }}
                  </td>

                  <td class="w-5 text-xs">
                    <span class="px-2 py-2.5 block w-full h-full">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                        <path
                          fill-rule="evenodd"
                          d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Summary table end -->
      </div>
    </div>

    <!-- Share modal -->
    <div
      id="addQuestionsModal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div class="relative w-full max-w-[840px] max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-xl shadow dark:bg-gray-800">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-4 px-6 border-b rounded-t dark:border-gray-700">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">Add Questions</h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="addQuestionsModal"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <p class="text-base leading-relaxed">
              Add one or multiple questions to the evaluation list. New questions are separated by a new line.
            </p>

            <ResizableTextarea
              id="new-questions"
              :min-rows="5"
              :max-rows="10"
              :model-value="newQuestions"
              placeholder="Type a question.."
              @update:model-value="newQuestions = $event"
            />
          </div>
          <!-- Modal footer -->
          <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-700">
            <button data-modal-hide="addQuestionsModal" type="button" class="ml-auto gray-btn">Cancel</button>
            <button
              data-modal-hide="addQuestionsModal"
              data-test="add-questions"
              type="button"
              class="primary-btn"
              @click="addQuestions"
              :disabled="savingQuestions"
            >
              Save Questions
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- drawer init and toggle -->
    <div class="text-center">
      <button
        ref="sideDrawerButton"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        type="button"
        style="visibility: hidden"
        data-drawer-backdrop="false"
        data-drawer-target="drawer-chat"
        data-drawer-show="drawer-chat"
        data-drawer-placement="right"
        aria-controls="drawer-chat"
      >
        Show right drawer
      </button>
    </div>

    <!-- drawer component for tables-->
    <div
      id="drawer-chat"
      ref="drawerChat"
      class="fixed top-0 right-0 z-[42] h-screen p-0 transition-transform translate-x-full bg-white w-full max-w-[1600px] dark:bg-gray-950"
      tabindex="-1"
      aria-labelledby="drawer-right-label"
      @keyup="handleNavigation"
    >
      <div class="flex items-center justify-between gap-4 py-5 px-6 border-b primary-border">
        <div ref="questionOptionsRef" class="relative w-full max-w-[520px]">
          <div
            class="cursor-pointer px-4 py-2.5 border primary-border w-full rounded-12 flex items-center justify-between"
            @click="toggleQuestionDropdown"
          >
            <p class="text">
              {{ openedQuestion?.question_text }}
            </p>
            <Icon :icon="isQuestionDropdownOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'" class="w-4 h-4" />
          </div>
          <div
            v-if="isQuestionDropdownOpen"
            class="absolute overflow-hidden shadow-md z-30 w-full top-full left-0 border primary-border bg-white dark:bg-gray-800 rounded-12"
            @keyup.stop
          >
            <div class="p-[1px] mb-1">
              <Search
                :model-value="search_questions_dropdown"
                placeholder="Search..."
                class="!max-w-full !rounded-12"
                @update:model-value="search_questions_dropdown = $event"
              />
            </div>
            <ul class="p-0 m-0 list-none max-h-[450px] overflow-y-auto w-full">
              <li
                v-for="question in allRanQuestions"
                :key="question.id"
                class="p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center gap-4"
                :class="{
                  'bg-gray-100 dark:bg-gray-600': question.id === openedQuestion?.id,
                }"
                @click="openDrawerChat(question)"
              >
                {{ question.question_number }}. {{ question.question_text }}
              </li>
            </ul>
          </div>
        </div>
        <button
          v-if="openedQuestion?.org_id == org.id && !isDocumentBlockedByCurrentUser"
          v-tooltip="'Forcefully unblocking this element might remove changes made by the other user.'"
          class="ml-auto inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-xl hover:bg-red-700 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-800"
          @click="takeOverDocument(openedQuestion?.id)"
        >
          <Icon icon="mdi:account-cancel" class="w-4 h-4 mr-2" />
          <div class="flex flex-col text-left">
            <span>Take Over</span>
            <span v-if="documentBlockedBy?.user_id !== user.id" class="text-xs">
              Blocked by User {{ documentBlockedBy?.user_id }} since
              {{ formatDistanceToNow(parseISO(documentBlockedBy?.timestamp), { addSuffix: true }) }}
            </span>
            <span v-else class="text-xs">
              Blocked by yourself in another window since
              {{ formatDistanceToNow(parseISO(documentBlockedBy?.timestamp), { addSuffix: true }) }}
            </span>
          </div>
        </button>
        <button
          ref="closeButtonTable"
          type="button"
          data-drawer-hide="drawer-chat"
          aria-controls="drawer-chat"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          @click="handleBackdropClick"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="sr-only">Close menu</span>
        </button>
      </div>

      <div class="flex w-full h-full min-h-screen">
        <div class="w-1/2 h-full overflow-y-auto no-scrollbar p-6 border-r primary-border">
          <ChatComponent :eval_mode="true" :opened-question="openedQuestion" />
        </div>

        <div class="w-1/2 h-full overflow-y-auto p-6 no-scrollbar pb-40">
          <!-- reaction buttons at the top TODO still need styling -->
          <div v-if="openedQuestion?.status !== 'running'" class="space-y-4">
            <div class="flex items-center w-full rounded-12 border primary-border mt-[1px]">
              <p class="block text-base font-medium sub-text px-4 py-2.5 border-r primary-border w-max shrink-0">
                <span>Q{{ openedQuestion?.question_number }}</span>
              </p>
              <div v-if="openedQuestionRunTimestamps.length > 0" ref="timestampOptionsRef" class="relative w-full">
                <div class="cursor-pointer flex items-center gap-4 w-full px-4 py-2.5" @click="toggleTimestampDropdown">
                  <p class="min-w-[120px]">
                    {{ activeTimestamp }}
                  </p>
                  <p class="inline-flex justify-center w-4 shrink-0">
                    <Icon
                      :icon="activeTimestampEmoji"
                      class="w-full"
                      :class="{
                        'text-blue-800 dark:text-blue-300': activeTimestampStatus === 'pass',
                        'text-yellow-800 dark:text-yellow-300': activeTimestampStatus === 'unclear',
                        'text-red-800 dark:text-red-300': activeTimestampStatus === 'fail',
                      }"
                    />
                  </p>
                  <Icon
                    :icon="isTimestampDropdownOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'"
                    class="w-4 h-4 ml-auto"
                  />
                </div>
                <ul
                  v-if="isTimestampDropdownOpen"
                  class="absolute top-full left-0 p-0 m-0 list-none border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 rounded-md shadow-md z-30 w-max"
                >
                  <li
                    v-for="(timestamp, index) in openedQuestionRunTimestamps"
                    :key="index"
                    class="py-2 px-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center gap-4"
                    :class="{
                      'bg-gray-100 dark:bg-gray-600': timestamp.formattedTimestamp === activeTimestamp,
                    }"
                    @click="changeOpenedQuestionTimestamp(timestamp.formattedTimestamp)"
                  >
                    <span class="inline-block w-max min-w-[120px]">
                      {{ timestamp.formattedTimestamp }}
                    </span>
                    <span class="inline-flex w-4 justify-center shrink-0">
                      <Icon
                        :icon="timestamp.emoji"
                        class="w-full"
                        :class="{
                          'text-blue-800 dark:text-blue-300': timestamp.status === 'pass',
                          'text-yellow-800 dark:text-yellow-300': timestamp.status === 'unclear',
                          'text-red-800 dark:text-red-300': timestamp.status === 'fail',
                        }"
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex xl:justify-between items-center w-full flex-col xl:flex-row gap-4">
              <div class="flex rounded-12 items-center gap-4 w-full" role="group">
                <RadioInput
                  label="Correct"
                  :checked="openedQuestion?.status === 'pass'"
                  class="w-1/3"
                  @toggle="
                    (openedQuestion.status = 'pass'),
                      extractCorrectQuery(openedQuestion),
                      sendQuestionRunFeedback(openedQuestion)
                  "
                />
                <RadioInput
                  label="Clarification Needed"
                  :checked="openedQuestion?.status === 'unclear'"
                  class="w-1/3"
                  @toggle="(openedQuestion.status = 'unclear'), sendQuestionRunFeedback(openedQuestion)"
                />
                <RadioInput
                  label="Incorrect"
                  :checked="openedQuestion?.status === 'fail'"
                  class="w-1/3"
                  @toggle="(openedQuestion.status = 'fail'), sendQuestionRunFeedback(openedQuestion)"
                />
              </div>
            </div>
          </div>
          <div v-else class="h-5" />
          <div v-if="openedQuestion" class="mt-4">
            <div class="input-group mt-6">
              <ResizableTextarea
                id="comment"
                :min-rows="3"
                :max-rows="10"
                :model-value="openedQuestion.comment"
                :disabled="
                  !openedQuestion?.status || openedQuestion?.status === 'notrun' || openedQuestion?.status === 'running'
                "
                placeholder="Write a comment.."
                class="!rounded-12"
                @update:model-value="openedQuestion.comment = $event"
                @change="sendQuestionRunFeedback(openedQuestion, true)"
                @click.stop
                @keyup.stop
              />
              <label for="comment">Comment</label>
            </div>
            <div class="space-y-4 mt-6">
              <div class="input-group">
                <ResizableTextarea
                  id="correct_query"
                  :min-rows="5"
                  :max-rows="10"
                  :model-value="openedQuestion.correct_query"
                  placeholder="Write a correct query.."
                  class="!rounded-12"
                  @update:model-value="openedQuestion.correct_query = $event"
                  @change="saveQuestion(openedQuestion, true)"
                  @click.stop
                  @keyup.stop
                />
                <label for="correct_query">Correct query (optional)</label>
              </div>
              <button
                v-if="openedQuestion?.status === 'fail'"
                class="primary-btn block ml-auto"
                :disabled="suggestingDocumentation"
                @click="suggest_solution"
              >
                Suggest Solution

                <LoadingIcon
                  v-if="suggestingDocumentation"
                  class="inline !w-3 text-gray-500 dark:text-gray-400 animate-spin"
                />
              </button>
            </div>
            <div v-if="openedQuestion?.status !== 'fail' && openedQuestion?.correct_query" class="space-y-4 mt-4">
              <div class="w-full flex items-center gap-4">
                <p class="">Automatically evaluate answer</p>
                <ToggleButton
                  :active="openedQuestion?.auto_evaluate_answer"
                  @update:active="toggleAutoEvaluateAnswer"
                />
              </div>
              <!-- If the question has the property rerun_fail_reason, print out this rerun_fail_reason -->
              <div v-if="openedQuestion?.rerun_fail_reason" class="text-red-800 dark:text-red-300">
                <p class="font-medium">Rerun Fail Reason:</p>
                <p v-dompurify-html="openedQuestion?.rerun_fail_reason" />
              </div>

              <div
                v-if="
                  openedQuestion?.rerun_fail_reason && openedQuestion.df1?.length > 0 && openedQuestion.df2?.length > 0
                "
                class="mt-4"
              >
                <!-- Comparison Table -->
                <DataTable :table-data-string="comparisonTable" />
                <!-- End Comparison Table -->
              </div>
            </div>
            <div
              v-if="
                openedQuestion?.auto_evaluate_answer &&
                openedQuestion?.status !== 'fail' &&
                openedQuestion?.correct_query
              "
              class="mt-4 space-y-4"
            >
              <p class="">
                Similarity is calculated as the difference between the result set of the accepted query and the new
                query. The basis for that is an automated column mapping and then a comparison of the actual values. For
                example, if the accepted result set has 40 (20 rows x 2 columns) values and the new result set has 36 of
                those as well, the similarity is 90%.
              </p>
              <p class="flex items-center gap-1">
                <span class="font-bold">Note:</span>
                for two numerical values the similarity is computed proportionally (100 and 105 are 95% similar).
              </p>
              <div ref="similarityOptionsRef" class="shrink-0 relative w-max">
                <div
                  class="cursor-pointer p-2 border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 rounded-md flex items-center gap-4 w-[150px]"
                  @click="toggleSimilarityDropdown"
                >
                  <p class="w-full flex items-center justify-between">
                    <span>Similarity:</span>
                    <span>{{ (openedQuestion?.similarity || 1) * 100 }}%</span>
                  </p>
                </div>
                <ul
                  v-if="isSimilarityDropdownOpen"
                  class="absolute top-full left-0 p-0 m-0 list-none border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 rounded-md shadow-md z-30 block w-full"
                >
                  <li
                    v-for="(option, index) in similarityOptions"
                    :key="index"
                    class="p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 flex flex-col gap-1 w-full"
                    :class="{
                      'bg-gray-100 dark:bg-gray-600': similarity === option.value,
                    }"
                    @click="changeSimilarity(option.value)"
                  >
                    <span class="font-medium">{{ option.value * 100 }}%</span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-if="openedQuestion?.status === 'fail' && openedQuestion?.suggestedDocumentation"
              class="space-y-4 mt-8"
            >
              <MonacoEditor
                theme="vs-dark"
                language="yaml"
                :options="{
                  roundedSelection: true,
                  automaticLayout: true,
                  minimap: {
                    enabled: false,
                  },
                  formatOnPaste: true,
                  formatOnType: true,
                  formatOnSave: true,
                  tabSize: 2,
                  insertSpaces: true,
                  autoIndent: true,
                  fontSize: '14px',
                  lineNumbers: 'on',
                  scrollBeyondLastLine: false,
                }"
                :height="400"
                :diff-editor="false"
                placeholer="Instructions for documentation"
                :value="openedQuestion.suggestedDocumentation"
                @update:model-value="openedQuestion.suggestedDocumentation = $event"
                @keyup.stop
              />
              <button class="primary-btn block ml-auto" :disabled="applyingDocumentation" @click="applyDocumentation">
                Append to Model
                <LoadingIcon
                  v-if="applyingDocumentation"
                  class="inline !w-3 text-gray-500 dark:text-gray-400 animate-spin"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end side drawer for tables -->

    <div
      v-if="backdropIsVisible"
      id="my_backdrop"
      class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-[41] cursor-pointer"
      @click="handleBackdropClick(false)"
    />
  </div>
</template>

<script>
  import { ref, computed } from 'vue'
  import axios from '@/axiosInstance'
  import { useUserStore } from '@/stores/userStore'
  import { useOrgStore } from '@/stores/orgStore'
  import { storeToRefs } from 'pinia'
  import { initDrawers, initModals } from 'flowbite'
  import { useGlobalStore } from '@/stores/globalStore'
  import { useNewChatStore } from '@/stores/chatStore'
  import ChatComponent from '@/components/ChatComponent.vue'
  import TrashIcon from '@/components/icons/TrashIcon.vue'
  import LoadingIcon from '@/components/icons/LoadingIcon.vue'
  import Popup from './Popup.vue'
  import { format, parseISO, formatDistanceToNow } from 'date-fns'
  import { Icon } from '@iconify/vue'
  import ToggleButton from './ToggleButton.vue'
  import MonacoEditor from 'monaco-editor-vue3'
  import * as yaml from 'js-yaml'
  import DataTable from './DataTable.vue'
  import getTextAreaRows from '@/hooks/getTextareaRows'

  export default {
    components: {
      ChatComponent,
      TrashIcon,
      LoadingIcon,
      Popup,
      Icon,
      ToggleButton,
      MonacoEditor,
      DataTable,
    },
    setup() {
      const orgStore = useOrgStore()
      const userStore = useUserStore()
      const { user } = storeToRefs(userStore)
      const { org } = storeToRefs(orgStore)
      const globalStore = useGlobalStore()
      const notify = globalStore.notify

      const openedQuestion = ref(null)

      // expand or collapse sidenav
      const isMobileDevice = ref(window.matchMedia('(max-width: 520px)').matches)

      // Watch for changes in the window width
      const mediaQuery = window.matchMedia('(max-width: 520px)')
      const handleMediaQueryChange = mediaQuery => {
        isMobileDevice.value = mediaQuery.matches
      }

      // Set the initial state based on the window width
      handleMediaQueryChange(mediaQuery)

      // Watch for changes in the window width
      mediaQuery.addEventListener('change', handleMediaQueryChange)

      const newChatStore = useNewChatStore()
      const { currentChatId } = storeToRefs(newChatStore)

      const isTimestampDropdownOpen = ref(false)
      const toggleTimestampDropdown = () => {
        isTimestampDropdownOpen.value = !isTimestampDropdownOpen.value
      }
      const timestampOptionsRef = ref(null)
      const handleTimestampClickOutside = e => {
        if (timestampOptionsRef.value && !timestampOptionsRef.value.contains(e.target)) {
          isTimestampDropdownOpen.value = false
        }
      }

      const isSimilarityDropdownOpen = ref(false)
      const toggleSimilarityDropdown = () => {
        isSimilarityDropdownOpen.value = !isSimilarityDropdownOpen.value
      }
      const similarityOptionsRef = ref(null)
      const handleSimilarityClickOutside = e => {
        if (similarityOptionsRef.value && !similarityOptionsRef.value.contains(e.target)) {
          isSimilarityDropdownOpen.value = false
        }
      }

      const isQuestionDropdownOpen = ref(false)
      const toggleQuestionDropdown = () => {
        isQuestionDropdownOpen.value = !isQuestionDropdownOpen.value
      }
      const questionOptionsRef = ref(null)
      const handleQuestionClickOutside = e => {
        if (questionOptionsRef.value && !questionOptionsRef.value.contains(e.target)) {
          isQuestionDropdownOpen.value = false
        }
      }

      function handleAllClickOutside(e) {
        handleTimestampClickOutside(e)
        handleSimilarityClickOutside(e)
        handleQuestionClickOutside(e)
      }

      const suggestingDocumentation = ref(false)
      const suggest_solution = () => {
        suggestingDocumentation.value = true
        axios
          .post('/api/suggest_solution', { question: openedQuestion.value })
          .then(response => {
            // Response should always be an onject including status and message
            // If status is "success", update suggestedDocumentation
            // If status is "error", show error message
            if (response.data.status === 'success') {
              notify.success('Check the suggested documentation below')
              // set response.data.message as openedQuestion.suggestedDocumentation
              openedQuestion.value.suggestedDocumentation = response.data.message
            } else {
              notify.error(response.data.message)
            }
          })
          .catch(error => {
            notify.error(error)
          })
          .finally(() => {
            suggestingDocumentation.value = false
          })
      }

      const applyingDocumentation = ref(false)
      const applyDocumentation = () => {
        applyingDocumentation.value = true
        // First check if the YAML is valid
        if (!isValidYAML(openedQuestion.value.suggestedDocumentation)) {
          notify.error({
            title: 'Error',
            message: 'The YAML is not valid',
            type: 'error',
          })
          return
        }

        axios
          .post('/api/apply_documentation', {
            documentation: openedQuestion.value.suggestedDocumentation,
          })
          .then(response => {
            // Response should always be an onject including status and message
            // If status is "success", update suggestedDocumentation
            // If status is "error", show error message
            if (response.data.status === 'success') {
              notify.success('Documentation applied successfully')
              openedQuestion.value.suggestedDocumentation = ''
            } else {
              notify.error(response.data.message)
            }
          })
          .catch(error => {
            notify.error(error)
          })
          .finally(() => {
            applyingDocumentation.value = false
          })
      }

      const isValidYAML = yamlString => {
        try {
          yaml.load(yamlString)
          return true
        } catch {
          return false
        }
      }

      const comparisonTable = computed(() => {
        if (!openedQuestion.value || !openedQuestion.value || !openedQuestion.value.df1) return ''

        const headers = Object.keys(openedQuestion.value.df1[0] || {})
          .map(key => `<th class="px-4 py-2 text-left">${key}</th>`)
          .join('')

        const rows = openedQuestion.value.df1
          .map((row1, index) => {
            const cells = Object.entries(row1)
              .map(([key, value]) => {
                const df2ValueExists =
                  openedQuestion.value.df2?.[index] && openedQuestion.value.df2[index][key] !== undefined
                const df2Value = df2ValueExists ? openedQuestion.value.df2[index][key] : 'N/A'

                if (df2ValueExists && value !== df2Value) {
                  return `<td class="px-4 py-2">
                          <span class="text-red-800 line-through">${value}</span>
                          <span class="text-green-600">${df2Value}</span>
                        </td>`
                } else if (!df2ValueExists) {
                  return `<td class="px-4 py-2">
                          <span class="text-red-800 line-through">${value}</span>
                          <span class="text-green-600">N/A</span>
                        </td>`
                } else {
                  return `<td class="px-4 py-2">${value}</td>`
                }
              })
              .join('')

            return `<tr>${cells}</tr>`
          })
          .join('')

        return `
            <table class="table-auto w-full mt-4">
              <thead>
                <tr>${headers}</tr>
              </thead>
              <tbody>${rows}</tbody>
            </table>
          `
      })

      return {
        user,
        notify,
        isMobileDevice,
        createNewChat: newChatStore.createNewChat,
        currentChatId,
        isTimestampDropdownOpen,
        toggleTimestampDropdown,
        timestampOptionsRef,
        isSimilarityDropdownOpen,
        toggleSimilarityDropdown,
        similarityOptionsRef,
        handleAllClickOutside,
        handleTimestampClickOutside,
        suggest_solution,
        suggestingDocumentation,
        openedQuestion,
        isValidYAML,
        applyDocumentation,
        applyingDocumentation,
        comparisonTable,
        getOrg: orgStore.getOrg,
        org,
        userStore,
        parseISO,
        formatDistanceToNow,
        isQuestionDropdownOpen,
        toggleQuestionDropdown,
        questionOptionsRef,
      }
    },
    data() {
      return {
        newQuestions: '',
        questions: [
          {
            id: 1,
            question_text: 'Loading ..',
            question_number: 1,
            status: 'notrun',
            comment: '',
            correct_query: '',
            auto_evaluate_answer: false,
            similarity: 1,
            rerun_fail_reason: '',
            df1: [],
            df2: [],
            suggestedDocumentation: '',
          },
        ],
        search_questions: '',
        page: 1,
        perPage: 10,
        backdropIsVisible: false,
        questionBeingEdited: null,
        deletingQuestion: false,
        summary: {},
        filterByStatus: 'all',
        statuses: [
          { value: 'all', label: 'All' },
          { value: 'pass', label: 'Correct' },
          { value: 'fail', label: 'Incorrect' },
          { value: 'unclear', label: 'Clarification Needed' },
          { value: 'running', label: 'Running' },
          { value: 'ready', label: 'Run Finished' },
          { value: 'notrun', label: 'Not Run' },
        ],
        selectedQuestions: [],
        runningQuestions: false,
        intervalIsSet: false,
        timestampActive: false,
        fetchingQuestions: false,
        numberOrder: 'asc',
        correct_query: '',
        similarityOptions: [
          {
            value: 1,
            label: 'Exact Match',
          },
          {
            value: 0.99,
            label: 'Almost Exact Match',
          },
          {
            value: 0.95,
            label: 'High Similarity',
          },
          {
            value: 0.9,
            label: 'Medium Similarity',
          },
        ],
        page_seed: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        search_questions_dropdown: '',
        loadingEvaluationQuestions: false,
        savingQuestions: false,
      }
    },
    computed: {
      documentBlockedBy() {
        if (!this.org?.blocked_elements) {
          return null
        }

        const blockedElement = this.org.blocked_elements.find(blockedElement => {
          return blockedElement.id === this.openedQuestion?.id
        })

        if (!blockedElement) {
          return null
        }

        return blockedElement
      },
      isDocumentBlockedByCurrentUser() {
        if (!this.org?.blocked_elements) {
          return true
        }

        const blockedElement = this.org.blocked_elements.find(blockedElement => {
          return blockedElement.id === this.openedQuestion?.id
        })

        if (!blockedElement) {
          return true
        }

        if (blockedElement.user_id === this.userStore.user.id && blockedElement.seed === this.page_seed) {
          return true
        }

        return false
      },
      questionIsRunning() {
        return this.questions.some(question => question.status === 'running')
      },
      filteredQuestionItems() {
        let questions = this.questions

        // add created_at (yesterday) to each question if it doesn't exist (old questions)
        questions = questions.map(question => {
          if (!question.created_at) {
            let date = new Date()
            date.setDate(date.getDate() - 1)
            let yesterday = date.getTime()
            return {
              ...question,
              created_at: yesterday,
              status: question.status ?? 'notrun',
            }
          }
          return question
        })

        // only sort if any of the questions was created in the last 1 minute
        if (questions.some(question => new Date(question.created_at) > new Date() - 60000)) {
          questions = questions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        }

        // sort the questions according to selected status
        switch (this.filterByStatus) {
          case 'all': {
            break
          }
          case 'pass': {
            const passQuestions = questions.filter(item => item.status === 'pass')
            const restQuestionsPass = questions.filter(item => item.status !== 'pass')
            questions = passQuestions.concat(restQuestionsPass)
            break
          }
          case 'unclear': {
            const unclearQuestions = questions.filter(item => item.status === 'unclear')
            const restQuestionsUnclear = questions.filter(item => item.status !== 'unclear')
            questions = unclearQuestions.concat(restQuestionsUnclear)
            break
          }
          case 'fail': {
            const failQuestions = questions.filter(item => item.status === 'fail')
            const restQuestionsFail = questions.filter(item => item.status !== 'fail')
            questions = failQuestions.concat(restQuestionsFail)
            break
          }
          case 'notrun': {
            const notrunQuestions = questions.filter(item => item.status === 'notrun')
            const restQuestionsNotrun = questions.filter(item => item.status !== 'notrun')
            questions = notrunQuestions.concat(restQuestionsNotrun)
            break
          }
          case 'ready': {
            const readyQuestions = questions.filter(item => item.status === 'ready')
            const restQuestionsReady = questions.filter(item => item.status !== 'ready')
            questions = readyQuestions.concat(restQuestionsReady)
            break
          }
          case 'running': {
            const runningQuestions = questions.filter(item => item.status === 'running')
            const restQuestionsRunning = questions.filter(item => item.status !== 'running')
            questions = runningQuestions.concat(restQuestionsRunning)
            break
          }
          case 'question_number': {
            if (this.numberOrder === 'asc') {
              questions = questions.sort((a, b) => a.question_number - b.question_number)
            } else {
              questions = questions.sort((a, b) => b.question_number - a.question_number)
            }
            break
          }
          default: {
            break
          }
        }

        questions = questions
          .filter(item => {
            return (
              item.question_text.toLowerCase().includes(this.search_questions.toLowerCase()) ||
              item.status.toLowerCase().includes(this.search_questions.toLowerCase()) ||
              item.comment.toLowerCase().includes(this.search_questions.toLowerCase())
            )
          })
          .slice((this.page - 1) * this.perPage, (this.page - 1) * this.perPage + this.perPage)

        return questions
      },

      allQuestionIds() {
        // get all filtered question ids first
        const filteredQuestionIds = this.filteredQuestionItems
          .filter(question => question.status !== 'notrun' && question.status)
          .map(question => question.run_id)
          .filter(Boolean)

        // get all question ids
        const allQuestionIds = this.questions
          .filter(question => question.status !== 'notrun' && question.status)
          .map(question => question.run_id)
          .filter(Boolean)

        // remove filtered question ids from all question ids
        const remainingQuestionIds = allQuestionIds.filter(id => !filteredQuestionIds.includes(id))

        // return filtered question ids first and then the remaining question ids
        return filteredQuestionIds.concat(remainingQuestionIds)
      },
      allStatuses() {
        let statuses = ['all', ...this.questions.map(question => question.status).filter(Boolean)]

        // filter out duplicates
        statuses = statuses.filter((status, index) => statuses.indexOf(status) === index)

        return statuses
      },
      sortTooltip() {
        let currentIndex = this.allStatuses.indexOf(this.filterByStatus)
        let nextIndex = currentIndex + 1
        if (nextIndex >= this.allStatuses.length) {
          nextIndex = 0
        }
        const nextStatusValue = this.allStatuses[nextIndex]
        const nextStatus = this.statuses.find(status => status.value === nextStatusValue)?.label

        return nextStatus === 'All' ? 'Clear sort' : `Sort by ${nextStatus} status`
      },
      sortNumberTooltip() {
        if (this.numberOrder === 'asc') {
          return 'Sort by descending order'
        } else {
          return 'Sort by ascending order'
        }
      },
      shownQuestions() {
        if (this.search_questions) {
          return this.filteredQuestionItems.length
        }
        return this.page * this.perPage > this.questions.length ? this.questions.length : this.page * this.perPage
      },
      allQuestions() {
        return this.search_questions ? this.filteredQuestionItems.length : this.questions.length
      },
      allRanQuestions() {
        if (!this.questions) {
          return []
        }
        return this.questions
          ?.filter(question => question.status !== 'notrun')
          .filter(question =>
            question.question_text?.toLowerCase()?.includes(this.search_questions_dropdown?.toLowerCase())
          )
      },
      activeTimestamp() {
        try {
          if (this.openedQuestion?.run_id) {
            const activeTimestamp = this.openedQuestionRunTimestamps.find(
              timestamp => timestamp.id === this.openedQuestion.run_id
            )

            if (activeTimestamp) {
              return activeTimestamp.formattedTimestamp
            }

            return ''
          }
        } catch (error) {
          console.log(error)
          return ''
        }

        return ''
      },
      activeTimestampEmoji() {
        try {
          if (this.openedQuestion?.run_id) {
            const activeTimestamp = this.openedQuestionRunTimestamps.find(
              timestamp => timestamp.id === this.openedQuestion.run_id
            )

            if (activeTimestamp) {
              return activeTimestamp.emoji
            } else {
              return ''
            }
          }
        } catch (error) {
          console.log(error)
          return ''
        }

        return ''
      },
      activeTimestampStatus() {
        try {
          if (this.openedQuestion?.run_id) {
            const activeTimestamp = this.openedQuestionRunTimestamps.find(
              timestamp => timestamp.id === this.openedQuestion.run_id
            )

            if (activeTimestamp) {
              return activeTimestamp.status
            } else {
              return ''
            }
          }
        } catch (error) {
          console.log(error)
          return ''
        }

        return ''
      },
      openedQuestionRunTimestamps() {
        try {
          if (this.openedQuestion?.runs) {
            const runs = this.openedQuestion.runs.filter(run => run.timestamp)

            let allTimestamps = runs.map(run => {
              const formattedTimestamp = this.formatTimestampInUTC(run.timestamp)

              return {
                timestamp: run.timestamp,
                formattedTimestamp,
                id: run.id,
                status: run.status,
                emoji:
                  run.status === 'pass'
                    ? 'gridicons:checkmark'
                    : run.status === 'fail'
                      ? 'ic:round-close'
                      : run.status === 'unclear'
                        ? 'ic:baseline-question-mark'
                        : '',
              }
            })

            return allTimestamps
          }

          return []
        } catch (error) {
          console.log(error)
          return []
        }
      },
      correctQueryRows() {
        return getTextAreaRows(this.openedQuestion.correct_query)
      },
    },
    watch: {
      questions: {
        handler(val) {
          if (this.openedQuestion) {
            // hack to make sure the comment does not change when the questions are fetched
            val.forEach(question => {
              if (question.id === this.openedQuestion.id) {
                question.comment = this.openedQuestion.comment
              }

              // Add the property correct_query to the question object, if it does not exist
              if (!question.correct_query) {
                question.correct_query = ''
              }
            })
          } else {
            const current_id = this?.$route?.query?.c || ''

            if (current_id) {
              this.openedQuestion = val.find(question => question.run_id === current_id || question.id === current_id)
            }
          }
        },
        deep: true,
      },
      questionIsRunning(val) {
        if (val) {
          this.refreshQuestionStatus()
        } else {
          this.fetchQuestions()
          this.fetchSummary()
          this.runningQuestions = false
        }
      },
      $route: {
        handler(val, oldVal) {
          if (this.openedQuestion && this.$route.query.c) {
            return
          }

          if (val?.name !== oldVal?.name) {
            if (this.$route.query.timestamp) {
              this.timestampActive = true
              this.fetchQuestionsByTimestamp(this.$route.query.timestamp)
            } else {
              this.fetchQuestions(true)
              this.timestampActive = false
            }
          }
        },
        immediate: true,
      },
      '$route.query.timestamp': {
        handler(val) {
          if (val) {
            this.timestampActive = true
            this.fetchQuestionsByTimestamp(val)
          } else {
            this.timestampActive = false
            this.fetchQuestions(true)
          }
        },
        immediate: true,
      },
      '$route.query': {
        handler(val, oldVal) {
          if (!val.c && !val.timestamp && !oldVal.c && !oldVal.timestamp) {
            return
          }

          if (val.c !== oldVal.c && val.timestamp !== oldVal.timestamp) {
            this.openedQuestion = this.questions.find(question => question.run_id === val.c)
          }
        },
      },
      openedQuestion: {
        async handler(newVal, oldVal) {
          if (newVal?.id !== oldVal?.id) {
            if (newVal?.id) {
              await this.getOrg()

              if (!this.isDocumentBlockedByCurrentUser) {
                console.log('not blocked by current user')
              } else {
                this.blockDocument(newVal.id)
              }
            } else {
              this.unblockDocument(oldVal?.id)
            }
          }
        },
        deep: true,
      },
    },
    mounted() {
      document.addEventListener('click', this.handleAllClickOutside)

      initDrawers()
      initModals()
      if (this.$route.query.timestamp) {
        this.timestampActive = true
        this.fetchQuestionsByTimestamp(this.$route.query.timestamp)
      } else {
        this.fetchQuestions(true)
        this.timestampActive = false
      }
      this.fetchSummary()
      document.title = 'Eval · Settings · Dot'

      const current_id = this?.$route?.query?.c || ''

      if (current_id) {
        this.$refs.sideDrawerButton.click()
        this.backdropIsVisible = true
        if (this.questions?.length > 0) {
          this.openedQuestion = this.questions.find(
            question => question.run_id === current_id || question.id === current_id
          )
        }
      }
    },
    created() {
      // Get org information from the API using axios
      axios
        .get('/api/org')
        .then(response => {
          this.enabled_interpretation = response.data.enabled_interpretation
          this.enabled_dotml = response.data.enabled_dotml
          // set enforce_dotml to true if it is not set
          this.enforce_dotml = response.data.enforce_dotml ?? true
          this.enabled_advanced_viz = response.data.enabled_advanced_viz ?? false
        })
        .catch(error => {
          console.log(error)
        })

      this.$nextTick(() => {
        this.resizeTextareas()
      })
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleAllClickOutside)
    },
    methods: {
      blockDocument(id) {
        axios
          .post('/api/block_element', { id: id, seed: this.page_seed }, { withCredentials: true })
          .then(() => {
            this.getOrg()
          })
          .catch(error => {
            console.log(error)
          })
      },
      unblockDocument(id) {
        axios.post('/api/unblock_element', { id: id, seed: this.page_seed }, { withCredentials: true }).then(() => {
          this.getOrg()
        })
      },
      unblockOpenedQuestion() {
        if (this.openedQuestion?.id) {
          this.unblockDocument(this.openedQuestion.id)
        }
      },
      takeOverDocument(id) {
        axios
          .post('/api/take_over_element', { id: id, seed: this.page_seed }, { withCredentials: true })
          .then(() => {
            this.getOrg()
          })
          .catch(error => {
            console.log(error)
          })
      },
      extractCorrectQuery(question) {
        if (question.correct_query) {
          return
        }

        axios.post('/api/extract_current_query', { question }).then(response => {
          if (response.data.status === 'success') {
            question.correct_query = response.data.message
            this.saveQuestion(question)
          } else {
            this.notify.error(response.data.message)
          }
        })
      },

      fetchQuestions(first = false) {
        if (this.timestampActive && this.$route?.query?.timestamp) {
          this.fetchQuestionsByTimestamp(this.$route.query.timestamp)
          return
        }

        if (this.fetchingQuestions) {
          return
        }

        this.fetchingQuestions = true

        this.loadingEvaluationQuestions = first

        axios
          .get('/api/eval_questions')
          .then(response => {
            // questions might contain an array called runs, that contains a timestamp and a status
            // we want to assign the last status to the question directly, if not runs, we want to assign notrun
            if (first) {
              this.questions = response.data.map(question => {
                if (question.runs && question.runs.length > 0) {
                  // order runs by timestamp ascending
                  // create a copy of the runs array to avoid mutating the original array
                  let runs = [...question.runs]
                  runs.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
                  question.runs = runs
                  question.status = runs[runs.length - 1].status
                  question.run_id = runs[runs.length - 1].id
                  if (this.$route.query.timestamp) {
                    question.comment = runs[runs.length - 1].comment
                  }
                } else {
                  question.status = 'notrun'
                }
                return question
              })
            } else {
              // sort the questions according to the existing order of questions
              this.questions = this.questions.map(question => {
                const q = response.data.find(res => res.id === question.id)
                if (q) {
                  if (q.runs && q.runs.length) {
                    // get the last run status
                    const lastRun = q.runs[q.runs.length - 1]
                    question.status = lastRun?.status
                    if (this.$route.query.timestamp) {
                      question.comment = lastRun?.comment
                    }
                  }
                  question.run_id = q.run_id
                  question.question_text = q.question_text
                }

                if (this.openedQuestion && this.openedQuestion.id === question.id) {
                  if (this.openedQuestion.auto_evaluate_answer && !question.auto_evaluate_answer) {
                    this.openedQuestion = {
                      ...question,
                      auto_evaluate_answer: this.openedQuestion.auto_evaluate_answer,
                    }
                  } else {
                    this.openedQuestion = question
                  }
                }
                return question
              })
            }

            this.$nextTick(() => {
              this.resizeTextareas()
            })
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.fetchingQuestions = false
            this.loadingEvaluationQuestions = false
          })
      },

      async fetchQuestionsStatus() {
        try {
          const response = await axios.get('/api/eval_questions_status')
          this.questions = this.questions.map(question => {
            const q = response.data.find(res => res.id === question.id || res.run_id === question.run_id)

            if (q) {
              // get the last run status
              const lastRun = q.runs[q.runs.length - 1]
              question.status = lastRun?.status ?? 'notrun'
              question.run_id = q.run_id
              question.runs = q.runs
            }
            return question
          })

          if (this.openedQuestion) {
            const question = response.data.find(question => question.id === this.openedQuestion.id)

            if (question) {
              if (this.openedQuestion.run_id && this.$route.query.c !== this.openedQuestion.run_id) {
                this.$router.push({ query: { c: question.run_id } })
              }

              // update current question runs
              this.openedQuestion = {
                ...this.openedQuestion,
                runs: question.runs,
                status: question.runs[question.runs.length - 1].status,
              }
            }
          }

          return response.data
        } catch (error) {
          console.log(error)
        }
      },

      async fetchQuestionsByTimestamp(run_timestamp) {
        if (this.fetchingQuestions) {
          return
        }

        const encodedTimestamp = encodeURIComponent(run_timestamp)
        try {
          this.fetchingQuestions = true
          const response = await axios.get(`/api/eval_questions_by_run_timestamp?run_timestamp=${encodedTimestamp}`)
          this.questions = response.data
        } catch (error) {
          console.log(error)
        } finally {
          this.fetchingQuestions = false
        }
      },

      fetchSummary() {
        axios
          .get('/api/question_run_summary')
          .then(response => {
            this.summary = this.processSummaryObject(response.data)
          })
          .catch(error => {
            console.log(error)
          })
      },

      toggleAll() {
        this.selectedQuestions =
          this.selectedQuestions.length === this.filteredQuestionItems.length
            ? []
            : this.filteredQuestionItems.map(item => item.id)
      },

      toggle(item) {
        if (this.selectedQuestions.includes(item.id)) {
          this.selectedQuestions = this.selectedQuestions.filter(id => id !== item.id)
        } else {
          this.selectedQuestions.push(item.id)
        }
      },

      autoResize(event) {
        const textarea = event.target
        textarea.style.height = 'max-content' // Reset the height
        textarea.style.height = textarea.scrollHeight + 'px' // Set the height based on the content
      },

      resizeTextareas() {
        this.$refs.questionInput?.forEach(input => {
          input.style.height = 'auto'
          input.style.height = input.scrollHeight + 'px'
        })
        this.$refs.commentInput?.forEach(input => {
          input.style.height = 'auto'
          input.style.height = input.scrollHeight + 'px'
        })
      },

      async addQuestions() {
        // Add questions to the list
        const questions = this.newQuestions
          .trim()
          .split('\n')
          .filter(question => question.trim() !== '')
          .map(question => {
            return {
              question_text: question.trim(),
              status: 'notrun',
              active: false,
              comment: '',
            }
          })

        this.saveQuestions(questions)
        this.newQuestions = ''
      },

      saveQuestion(question, comment_update = false) {
        // add one question, also updates
        if (this.$route.query.timestamp) {
          this.handleResetTimestamp()
        }

        if (!this.isDocumentBlockedByCurrentUser) {
          this.notify.error(
            'You are not allowed to edit this document, because it is currently blocked by another user'
          )
          return
        }

        axios
          .post('/api/save_eval_question', {
            eval_question: question,
          })
          .then(() => {
            if (comment_update) {
              this.notify.success('Comment updated')
            } else {
              this.notify.success('Question saved')
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.fetchQuestions()
          })
      },

      saveQuestions(eval_questions) {
        // add more than one question, does not update
        if (this.$route.query.timestamp) {
          this.handleResetTimestamp()
        }

        this.savingQuestions = true

        axios
          .post('/api/save_eval_questions', eval_questions)
          .then(() => {
            this.notify.success('Questions saved')
            setTimeout(() => {
              this.fetchQuestions(true)
            }, 300)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.savingQuestions = false
          })
      },

      runSelectedQuestions() {
        this.runningQuestions = true
        let questions = this.selectedQuestions.map(id => this.questions.find(question => question.id === id))

        axios
          .post('/api/run_questions', {
            questions,
          })
          .then(() => {
            // set all submitted questions to running
            this.questions = this.questions.map(question => {
              if (this.selectedQuestions.includes(question.id)) {
                question.status = 'running'
              }
              return question
            })

            // if filter is active, set active filter to running
            if (this.filterByStatus !== 'all') {
              this.filterByStatus = 'running'
            }

            this.selectedQuestions = []
            this.refreshQuestionStatus()
          })
          .catch(error => {
            console.log(error)
            this.runningQuestions = false
            this.selectedQuestions = []
          })
      },

      refreshQuestionStatus() {
        if (this.intervalIsSet) {
          return
        }

        this.intervalIsSet = true
        let interval = setInterval(() => {
          if (this.questionIsRunning) {
            this.fetchQuestionsStatus()
          } else {
            clearInterval(interval)
            this.intervalIsSet = false
            this.fetchQuestions()
            this.fetchSummary()
            this.runningQuestions = false
          }
        }, 5000)
      },

      deleteSelectedQuestions() {
        if (this.$route.query.timestamp) {
          this.handleResetTimestamp()
        }

        this.deletingQuestion = true

        // Array to store all the promises returned by axios.post()
        let deletePromises = []

        this.selectedQuestions.forEach(question_id => {
          // Push each axios.post() promise into the deletePromises array
          deletePromises.push(
            axios.delete(`/api/delete_eval_question`, {
              data: {
                eval_question_id: question_id,
              },
            })
          )
        })

        // Use Promise.all() to wait for all the delete requests to finish
        Promise.all(deletePromises)
          .then(() => {
            this.deletingQuestion = false
            this.notify.success('Questions deleted')
            this.fetchQuestions(true)
            this.selectedQuestions = []

            this.page = 1
          })
          .catch(error => {
            this.deletingQuestion = false
            console.log(error)
          })
      },

      openDrawerChat(question) {
        // don't open drawer if question status is 'notrun' or undefined
        if (question.status === 'notrun' || !question.status) {
          return
        }

        initDrawers()
        initModals()

        this.$refs.sideDrawerButton.click()
        this.backdropIsVisible = true
        this.openedQuestion = question

        if (this.$refs.drawerChat) {
          this.$refs.drawerChat.focus()
        }

        // let chat_id = question.run_id ?? '3056cd50-ae05-4ac1-9f41-d6f3342e0d7c'
        let chat_id = question.run_id ?? question.id

        if (!chat_id) {
          this.createNewChat()
          chat_id = this.currentChatId
        }
        // if question has a chat_id, the chat is already created
        let currentQuery = Object.assign({}, this.$route.query)
        currentQuery.c = chat_id
        this.$router.push({ name: 'EvaluationQuestion', query: currentQuery })
      },

      sendQuestionRunFeedback(question, comment_update = false) {
        if (comment_update && !this.$route.query.timestamp && question) {
          this.saveQuestion(question, true)
          return
        }

        axios
          .post('/api/set_question_run_status', {
            question_id: question.id,
            run_id: question.run_id,
            status: question.status,
            comment: question.comment || '',
          })
          .then(() => {
            this.fetchQuestions()
            this.fetchSummary()

            this.openedQuestion = {
              ...this.openedQuestion,
              status: question.status,
              comment: question.comment,
              runs: this.openedQuestion?.runs?.map(run => {
                if (run.id === question.run_id) {
                  run.status = question.status
                  run.comment = question.comment
                }
                return run
              }),
            }

            this.$nextTick(() => {
              if (comment_update) {
                this.notify.success('Comment updated')
              } else if (question.status === 'pass' && this.openedQuestion?.correct_query) {
                this.toggleAutoEvaluateAnswer(true)
              } else if (!this.openedQuestion?.correct_query) {
                this.extractCorrectQuery(this.openedQuestion)
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
      },

      processSummaryObject(data) {
        const result = []

        // Iterate over each timestamp in the input object
        for (const timestamp in data) {
          if (Object.prototype.hasOwnProperty.call(data, timestamp)) {
            const date = new Date(timestamp)
            const formattedDate = this.formatTimestampInUTC(timestamp)

            // Initialize counts for each status
            let counts = {
              ready: 0,
              pass: 0,
              fail: 0,
              unclear: 0,
              notrun: 0,
            }

            const value = data[timestamp]
            for (const status in counts) {
              if (Object.prototype.hasOwnProperty.call(value, status)) {
                counts[status] = value[status]
              }
            }

            // Calculate total count
            const totalCount = Object.values(counts).reduce((total, count) => total + count, 0)

            // Calculate percentages and format each status
            const formattedStatuses = {}
            for (const status in counts) {
              const value = counts[status]
              const percentage = totalCount === 0 ? 0 : ((value / totalCount) * 100).toFixed(0)
              formattedStatuses[status] = {
                value: value,
                percentage: percentage + '%',
              }
            }

            const entry = {
              date,
              run: formattedDate,
              ...formattedStatuses,
              total: totalCount,
              timestamp,
            }

            result.push(entry)
          }
        }

        // Sort the result array in descending order by timestamp
        result.sort((a, b) => new Date(b.date) - new Date(a.date))

        return result
      },

      nextQuestion(current_id) {
        let currentIndex = this.allQuestionIds.indexOf(current_id)

        let nextIndex = currentIndex + 1
        if (nextIndex >= this.allQuestionIds.length) {
          nextIndex = 0
        }
        let nextId = this.allQuestionIds[nextIndex]
        if (this.$route.query.timestamp) {
          this.$router.push({
            name: 'EvaluationTimestamp',
            query: { timestamp: this.$route.query.timestamp, c: nextId },
          })
        } else {
          this.$router.push({ name: 'EvaluationQuestion', query: { c: nextId } })
        }
        const nextQuestion = this.questions.find(question => question.run_id === nextId)

        if (nextQuestion.status === 'notrun' || !nextQuestion.status) {
          this.nextQuestion(nextId)
          return
        }

        this.openedQuestion = null
        this.openedQuestion = nextQuestion
      },

      prevQuestion(current_id) {
        let currentIndex = this.allQuestionIds.indexOf(current_id)
        let prevIndex = currentIndex - 1
        if (prevIndex < 0) {
          prevIndex = this.allQuestionIds.length - 1
        }
        let prevId = this.allQuestionIds[prevIndex]
        if (this.$route.query.timestamp) {
          this.$router.push({
            name: 'EvaluationTimestamp',
            query: { timestamp: this.$route.query.timestamp, c: prevId },
          })
        } else {
          this.$router.push({ name: 'EvaluationQuestion', query: { c: prevId } })
        }
        const prevQuestion = this.questions.find(question => question.run_id === prevId)

        if (prevQuestion.status === 'notrun' || !prevQuestion.status) {
          this.prevQuestion(prevId)
          return
        }

        this.openedQuestion = null
        this.openedQuestion = prevQuestion
      },

      handleNavigation(e) {
        if (this.debounceTimeout) clearTimeout(this.debounceTimeout)

        this.debounceTimeout = setTimeout(() => {
          if (e.key === 'ArrowRight') {
            this.nextQuestion(this.openedQuestion?.run_id)
          } else if (e.key === 'ArrowLeft') {
            this.prevQuestion(this.openedQuestion?.run_id)
          }

          if (this.$refs.drawerChat) {
            this.$refs.drawerChat.focus()
          }
        }, 300) // 300ms delay
      },

      switchStatus() {
        let currentIndex = this.allStatuses.indexOf(this.filterByStatus)
        let nextIndex = currentIndex + 1
        if (nextIndex >= this.allStatuses.length) {
          nextIndex = 0
        }
        this.filterByStatus = this.allStatuses[nextIndex]
        this.page = 1
      },

      handleBackdropClick(first_click = true) {
        initDrawers()
        initModals()

        this.backdropIsVisible = false
        this.openedQuestion = null
        if (this.$route.query.timestamp) {
          this.$router.push({ name: 'EvaluationTimestamp', query: { timestamp: this.$route.query.timestamp } })
        } else {
          this.$router.push({ name: 'Evaluation' })
        }

        if (first_click) {
          return
        }

        this.$refs.closeButtonTable.click()
      },

      openRun(run) {
        if (this.$route.query.timestamp && this.$route.query.timestamp === run.timestamp) {
          this.handleResetTimestamp()
          return
        }

        this.$router.push({ name: 'EvaluationTimestamp', query: { timestamp: run.timestamp } })
        this.timestampActive = true
      },

      sortByNumber() {
        this.numberOrder = this.numberOrder === 'asc' ? 'desc' : 'asc'
        this.filterByStatus = 'question_number'
      },

      handleResetTimestamp() {
        this.timestampActive = false
        this.$router.push({ name: 'Evaluation' })
      },

      changeOpenedQuestionTimestamp(formattedTimestamp) {
        try {
          const run = this.openedQuestionRunTimestamps.find(
            timestamp => timestamp.formattedTimestamp === formattedTimestamp
          )

          if (!run) {
            return
          }

          if (run.timestamp === 'current') {
            this.$router.push({ name: 'EvaluationQuestion', query: { c: run.id } })
            return
          }

          this.$router.push({
            name: 'EvaluationTimestamp',
            query: { timestamp: run.timestamp, c: run.id },
          })
        } catch (error) {
          console.log(error)
        } finally {
          this.isTimestampDropdownOpen = false
        }
      },

      formatTimestampInUTC(timestamp) {
        try {
          const date = parseISO(timestamp)
          const formattedDate = format(date, 'yyyy-MM-dd HH:mm', { timeZone: 'UTC' })
          return formattedDate
        } catch (error) {
          console.log(error)
          return timestamp
        }
      },

      saveAutoEvaluateAnswer(question = this.openedQuestion) {
        axios
          .post('/api/save_auto_evaluate_answer', {
            question_id: question.id,
            auto_evaluate_answer: question.auto_evaluate_answer || false,
            similarity: question.similarity || 1,
          })
          .then(() => {
            this.notify.success('Auto evaluate answer saved')
            this.fetchQuestions()
          })
          .catch(error => {
            console.log(error)
          })
      },

      toggleAutoEvaluateAnswer(value) {
        this.openedQuestion.auto_evaluate_answer = value
        this.saveAutoEvaluateAnswer({ ...this.openedQuestion, auto_evaluate_answer: value })
      },

      changeSimilarity(value) {
        this.openedQuestion.similarity = value
        this.isSimilarityDropdownOpen = false
        this.saveAutoEvaluateAnswer({ ...this.openedQuestion, similarity: value })
      },
    },
  }
</script>
